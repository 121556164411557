import { HexakaiBoardData } from "../hexakai-board/hexakai-board-data";
import { HexakaiBoardStateVisitor } from "../hexakai-board/hexakai-board-state-visitor";
import { HexakaiBoardCreatorParams } from "../models/hexakai-board-creator-params";
import { HexakaiBoardAssignment, HexakaiBoardState } from "../models/hexakai-board-state";
import { getRandom } from "../random/get-random";
import { shuffleInPlace, shuffleToNew } from "../random/shuffle";
import { HexakaiCellConstraint } from "./hexakai-cell-constraint";
import { HexakaiDfsRef } from "./hexakai-dfs-ref";
import { ObjectRef } from "./object-ref";

export class HexakaiGameSolver {

    /**
     * Solve the game by finding assignments
     * This will return 0, 1, or 2 assignments
     * 
     * In the board, empty or whitespace values will be assumed unset
     */
    solve(
        sourceGame: HexakaiBoardAssignment,
        expectedAssignment?: string,
        randomSeed?: any
    ): HexakaiBoardAssignment[] {
        // initialize the cloned game for analysis
        const boardData = new HexakaiBoardData(sourceGame.gameSize);

        // make the generator params
        const params: HexakaiBoardCreatorParams = {
            gameSize: sourceGame.gameSize,
            generatorSeed: getRandom(randomSeed)
        };

        // initialize constraints board
        let numValuesSet = 0;
        const cells: HexakaiCellConstraint[] = [];
        const constraintsBoard = boardData.createBoardState((row, col) => {
            const choicesList = shuffleToNew(boardData.getCellValues(), params.generatorSeed as number);

            const cell = new HexakaiCellConstraint(choicesList, row, col);
            cells.push(cell);
            return cell;
        });

        const visitor = new HexakaiBoardStateVisitor(constraintsBoard);

        // set permanently assigned values one by one
        for (let i=0; i<cells.length; i++) {
            const cell = cells[i];
            const sourceValue = sourceGame.cells[cell.getRow()][cell.getCol()].trim();
            if (sourceValue) {
                numValuesSet++;
                cell.setPermanentAssignment(sourceValue);

                // this assumes the input template is valid
                this.propagate(
                    visitor,
                    cells[i].getValue(),
                    cells[i].getRow(),
                    cells[i].getCol(),
                    params
                );
            }
        }

        // shuffle, then sort to ensure cells with values are put in the back
        shuffleInPlace(cells, params.generatorSeed);
        cells.sort((a, b) => {
            if (a.hasValueSet() === b.hasValueSet()) {
                return 0;
            } else if (a.hasValueSet()) {
                return 1;
            } else {
                return -1;
            }
        });

        // for thrashing prevention, keep track of backtracking
        const backtrackCounts: number[][] = [];
        for (let row = 0; row < boardData.getNumRows(); row++) {
            backtrackCounts.push([]);
            for (let col = 0; col < boardData.getNumCols(row); col++) {
                backtrackCounts[row].push(0);
            }
        }

        // make the recursive assignment
        const dfsRef = new HexakaiDfsRef();
        const response = this.tryAssign(
            visitor,
            boardData,
            cells,
            params,
            numValuesSet,
            dfsRef,
            backtrackCounts,
            new ObjectRef(0),
            new ObjectRef(0),
            expectedAssignment
        );

        return response === 3 ? [] : dfsRef.solutions;
    }

    /**
     * Recursively try to assign cell values
     * Returns true when two assignments are made, false otherwise
     * Check the numAssignments numRef to see if exactly one has been made
     */
    private tryAssign(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        boardData: HexakaiBoardData,
        cells: HexakaiCellConstraint[],
        gameParams: HexakaiBoardCreatorParams,
        depth: number,
        dfsRef: HexakaiDfsRef,
        backtrackCounts: number[][],
        zeroBacktrackingDepth: ObjectRef<number>,
        thrashingCounter: ObjectRef<number>,
        expectedAssignment?: string
    ): number /* 0 = false, 1 = thrashing backtrack, 2 = assigned, 3 = bad assignment found */ {
        const { cell, cellIndex } = this.getNextCellScanSwap(
            boardVisitor,
            cells,
            depth,
            gameParams
        );

        if (cell === true) {
            const board = this.mapConstraintsToAssignment(boardVisitor.getBoard());
            if (board) {
                if (expectedAssignment) {
                    const boardStr = this.mapBoardToString(board);
                    if (boardStr !== expectedAssignment) {
                        return 3;
                    }
                }

                // ensure that all assignments are filled out
                dfsRef.solutions.push(
                    board
                );
                return dfsRef.solutions.length === 2 ? 0 : 2;
            } else {
                return 0;
            }
        }

        const row = cell.getRow();
        const col = cell.getCol();

        // try to assign, if not already assigned
        // determine which choices will impact neighboring cells the least

        const choices = this.determineLeastImpacting(
            boardVisitor,
            // TODO: duplicate re-cloning
            [...cell.getChoices()],
            row,
            col,
            gameParams
        );

        let isThrashing = false;
        let depthIncremented = backtrackCounts[row][col] !== 0;
        for (const choice of choices) {
            // assign
            cell.setValue(choice);

            // propagate forward
            const minNumChoices = this.propagate(
                boardVisitor, choice, row, col, gameParams
            );

            // continue assignments
            if (minNumChoices > 0) {
                const response = this.tryAssign(
                    boardVisitor,
                    boardData,
                    cells,
                    gameParams,
                    depth + 1,
                    dfsRef,
                    backtrackCounts,
                    zeroBacktrackingDepth,
                    thrashingCounter,
                    expectedAssignment
                );
                if (response === 2 || response === 3) {
                    return response;
                } else if (response === 1) {
                    if (backtrackCounts[row][col] !== 0) {
                        isThrashing = true;
                        if (!depthIncremented) {
                            zeroBacktrackingDepth.value --;
                            depthIncremented = true;
                        }

                    } else {
                        thrashingCounter.value = 0;
                    }
                } else if (!depthIncremented) {
                    zeroBacktrackingDepth.value --;
                    depthIncremented = true;
                }
            }

            // unpropagate forward
            this.unpropagate(
                boardVisitor, choice, row, col
            );

            // unassign
            cell.unsetValue();

            if (isThrashing) {
                break;
            }
        }

        // swap back to ensure reverse order
        cells[cells.length - depth - 1] = cells[cellIndex];
        cells[cellIndex] = cell;

        // update backtrack counts
        backtrackCounts[cell.getRow()][cell.getCol()]++;

        if (isThrashing) {
            return 1;
        }

        thrashingCounter.value++;
        const max = this.calculateBacktrackThreshold(
            gameParams.gameSize,
            zeroBacktrackingDepth.value,
        );

        if (thrashingCounter.value >= max) {
            return 1;
        }

        // if nothing found, we've reached a dead end
        return 0;
    }

    /**
     * Calculate the number of backtracks maximally possible from a given depth
     * TODO: precompute or cache
     * TODO: derive better figure
     */
    private calculateBacktrackThreshold(n: number, depth: number): number {
        return n*n*n*n*n;
        //return n*n*n*n / (depth + 1);
    }

    /**
     * Get the next cell by scanning and swapping processed cells to the end
     */
    private getNextCellScanSwap(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        cells: HexakaiCellConstraint[],
        depth: number,
        gameParams: HexakaiBoardCreatorParams,
    ): { cell: HexakaiCellConstraint | true; cellIndex: number } {
        if (cells[0].hasValueSet()) {
            return { cell: true, cellIndex: - 1 };
        }

        let cell = cells[0];
        let cellIndex = 0;

        // find the cell with the lowest choices remaining
        // tie breaker: the cell that imposes the most constraints on other cells
        // TODO: add exit early if tie breaker is already at max

        for (let index = 1; index < cells.length - depth - 1; index++) {
            // TODO: this is for debugging purposes only
            // check can be removed when code is ready for production
            if (cells[index].getNumChoices() < cell.getNumChoices()) {
                cell = cells[index];
                cellIndex = index;
            }

            if (cells[index].getNumChoices() === cell.getNumChoices()) {
                const indexRow = cells[index].getRow();
                const indexCol = cells[index].getCol();

                if (
                    boardVisitor.getNumberConstraining(indexRow, indexCol)
                    > boardVisitor.getNumberConstraining(cell.getRow(), cell.getCol())
                ) {
                    cell = cells[index];
                    cellIndex = index;
                }
            }
        }

        // swap cells
        cells[cellIndex] = cells[cells.length - depth - 1];
        cells[cells.length - depth - 1] = cell;

        return {
            cell,
            cellIndex
        };
    }

    /**
     * Given available choices for a cell, sort based on which will impact
     * neighboring cells the least (LCV)
     */
    private determineLeastImpacting(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        values: string[],
        row: number,
        col: number,
        gameParams: HexakaiBoardCreatorParams
    ): string[] {
        // keep track of how many assignments remain for neighbors
        // if the current cell were to take on each value
        const valueCountMap = new Map<string, number>(
            values.map(vl => [vl, 0])
        );

        // propagate to the rest of the row
        for (const [cell, cellRow, cellCol] of boardVisitor.visitRowForward(row, 0, false)) {
            if (cellRow !== row || cellCol !== col) {
                for (const value of values) {
                    if (cell.tryGetValue() === value) {
                        valueCountMap.set(value, Infinity);
                    } else if (cell.hasChoice(value)) {
                        valueCountMap.set(
                            value,
                            valueCountMap.get(value)! + 1
                        );
                    }
                }
            }
        }

        // propagate the column down and to the left
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColLeft(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                for (const value of values) {
                    if (cell.tryGetValue() === value) {
                        valueCountMap.set(value, Infinity);
                    } else if (cell.hasChoice(value)) {
                        valueCountMap.set(
                            value,
                            valueCountMap.get(value)! + 1
                        );
                    }
                }
            }
        }

        // down and to the right
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColRight(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                for (const value of values) {
                    if (cell.tryGetValue() === value) {
                        valueCountMap.set(value, Infinity);
                    } else if (cell.hasChoice(value)) {
                        valueCountMap.set(
                            value,
                            valueCountMap.get(value)! + 1
                        );
                    }
                }
            }
        }

        // determine orders based on map values
        return [...valueCountMap.keys()]
            .filter(a => valueCountMap.get(a)! !== Infinity)
            .sort((a, b) => {
                return valueCountMap.get(b)! - valueCountMap.get(a)!
            }
        );
    }

    /**
     * Propagate a value forward, or unpropagate if value is null
     * If any cell no longer has values it can assign, return 0
     * Otherwise, return the min number of choices remaining
     */
    private propagate(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        value: string,
        row: number,
        col: number,
        gameParams: HexakaiBoardCreatorParams,
    ): number {
        let minNumChoices = Infinity;

        // propagate to the rest of the row
        for (const [cell, cellRow, cellCol] of boardVisitor.visitRowForward(row, 0, false)) {
            if (cellRow !== row || cellCol !== col) {
                cell.removeChoice(value);
                minNumChoices = Math.min(minNumChoices, cell.getNumChoices());

                if (minNumChoices > 0 && !this.verifyPropagatedCellsNeighborsCanSatisfy(
                    boardVisitor,
                    value,
                    cellRow,
                    cellCol,
                    0
                )) {
                    minNumChoices = 0;
                }

            }
        }

        // propagate the column down and to the left
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColLeft(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                cell.removeChoice(value);
                minNumChoices = Math.min(minNumChoices, cell.getNumChoices());

                if (minNumChoices > 0 && !this.verifyPropagatedCellsNeighborsCanSatisfy(
                    boardVisitor,
                    value,
                    cellRow,
                    cellCol,
                    1
                )) {
                    minNumChoices = 0;
                }

            }
        }

        // down and to the right
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColRight(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                cell.removeChoice(value);
                minNumChoices = Math.min(minNumChoices, cell.getNumChoices());

                if (minNumChoices > 0 && !this.verifyPropagatedCellsNeighborsCanSatisfy(
                    boardVisitor,
                    value,
                    cellRow,
                    cellCol,
                    2
                )) {
                    minNumChoices = 0;
                }

            }
        }

        return minNumChoices;
    }

    /**
     * Once we propagate a constraint, ensure that the cell which has received
     * the constraint (such as removing the value "3"), has a different neighbor
     * that can satisfy it. If not, we need to backtrack
     */
    private verifyPropagatedCellsNeighborsCanSatisfy(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        removedValue: string,
        propagatedRow: number,
        propagatedCol: number,
        type: number, // 0 means from row, 1 from down left, 2 from down right
    ): boolean {
        // check col down left
        if (type !== 1) {
            for (const [cell] of boardVisitor.visitColLeft(propagatedRow, propagatedCol)) {
                if (cell.hasChoice(removedValue) || cell.tryGetValue() === removedValue) {
                    return true;
                }
            }
        }

        // check col down right
        if (type !== 2) {
            for (const [cell] of boardVisitor.visitColRight(propagatedRow, propagatedCol)) {
                if (cell.hasChoice(removedValue) || cell.tryGetValue() === removedValue) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Undo a propagation
     */
    private unpropagate(
        boardVisitor: HexakaiBoardStateVisitor<HexakaiCellConstraint>,
        value: string,
        row: number,
        col: number
    ): void {
        // propagate to the rest of the row
        for (const [cell, cellRow, cellCol] of boardVisitor.visitRowForward(row, 0, false)) {
            if (cellRow !== row || cellCol !== col) {
                cell.addChoice(value)
            }
        }

        // propagate the column down and to the left
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColLeft(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                cell.addChoice(value)
            }
        }

        // down and to the right
        for (const [cell, cellRow, cellCol] of boardVisitor.visitColRight(row, col)) {
            if (cellRow !== row || cellCol !== col) {
                cell.addChoice(value)
            }
        }
    }

    /**
     * Map the constraints object to an assigment object
     */
    private mapConstraintsToAssignment(state: HexakaiBoardState<HexakaiCellConstraint>): HexakaiBoardAssignment | null {
        // cast to correct type
        const assignment: HexakaiBoardAssignment = {
            gameSize: state.gameSize,
            cells: []
        }
        let valueMissing = false;
        assignment.cells = state.cells.map(row => {
            return row.map(cell => {
                if (cell.hasValueSet()) {
                    return cell.getValue();
                }
                valueMissing = true;
                return "";
            })
        });
        return valueMissing ? null : assignment;
    }

    private mapBoardToString(board: HexakaiBoardAssignment): string {
        let str = "";
        for (let row=0; row<board.cells.length; row++) {
            for (let col=0; col<board.cells[row].length; col++) {
                str += board.cells[row][col];
            }
        }
        return str;
    }
}