import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class GeometricRatioRng extends BaseRng {

    private n = 1;
    private goldenRatio = (1 + Math.sqrt(5)) / 2;
    private multiply = 1;
    //private goldenRatio = Math.cbrt(3);
    private root = this.goldenRatio;

    constructor(params: RngParams) {
        super(params);

        if (params.data?.root) {
            this.root = params.data.root;
        }
    }

    
    protected getNext(): number {
        this.n = (this.n + this.root * this.multiply) % 1; // Wrap around between 0 and 1
        this.multiply ++;
    
        return this.n;
    }

}