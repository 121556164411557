import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class XorshiftRng extends BaseRng {
    private state0: number;
    private state1: number;

    constructor(rngParams: RngParams) {
        super(rngParams);

        // Initialize the state with a seed, using stringToNumber to derive a seed from a string if needed
        const seed = rngParams.seed ?? "default_seed"; // Replace with actual seed value if available
        this.state0 = Math.floor(this.stringToNumber(`${seed}`) * (2 ** 32)); // Normalize to 32-bit integer
        this.state1 = this.state0 ^ 0xdeadbeef; // Just a random initial state
    }

    protected getNext(): number {
        let s1 = this.state0;
        const s0 = this.state1;
        this.state0 = s0;

        s1 ^= s1 << 23; // Xorshift step
        s1 ^= s1 >> 17;
        s1 ^= s0;
        s1 ^= s0 >> 26;

        this.state1 = s1;

        // Return a number in the range [0, 1) by normalizing the 64-bit result
        const result = (s0 + s1) >>> 0; // Unsigned right shift to ensure non-negative
        return result / (2 ** 32); // Normalize to [0, 1)
    }
}
