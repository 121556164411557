import { YouTubePlaylistVideo } from "../models/youtube-playlist-video";

export class YouTubeService {

    private static instance: YouTubeService;
    private API_KEY = 'AIzaSyDBaGzSAqbWZAgSRfR_6bGSTpaU95aLrqs';
    private YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';

    constructor() {
        if (YouTubeService.instance) {
            return YouTubeService.instance;
        }

        YouTubeService.instance = this;
    }

    getVideosFromPlaylist(playlistId: string): Promise<YouTubePlaylistVideo[]> {
        return this.getVideosFromPlaylistPage(playlistId);
    }

    private async getVideosFromPlaylistPage(playlistId: string, pageToken?: any): Promise<YouTubePlaylistVideo[]> {
        const url = `${this.YOUTUBE_API_URL}/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${this.API_KEY}${pageToken ? `&pageToken=${pageToken}` : ''
            }`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        const items = data.items;

        // Recursively fetch next pages if available
        if (data.nextPageToken) {
            const nextPageItems = await this.getVideosFromPlaylistPage(playlistId, data.nextPageToken);
            return items.concat(nextPageItems);
        }

        return items;
    }
}