import { RngParams } from "../../models/rng-params";
import { getRandom } from "../get-random";
import { BaseRng } from "../rng";

export class CollatzRng extends BaseRng {

    private value: number;
    private seed: any;

    constructor(
        params: RngParams
    ) {
        super(params);
        if (typeof params.seed !== 'undefined') {
            this.seed = typeof params.seed === 'string'
                ? this.stringToNumber(params.seed)
                : params.seed;
        }
        this.value = this.getRandomStartValue();
    }

    getNext(): number {
        if (this.value === 1) {
            this.value = this.getRandomStartValue();
        } else if (this.value % 2 === 0) {
            this.value /= 2;
        } else {
            this.value *= 3;
            this.value += 1;
        }

        return parseFloat(`0.${this.value}`);
    }

    private getRandomStartValue(): number {
        const rand = getRandom(this.seed);
        return Math.floor(rand * 1000);
    }
}