import { BaseRng } from "../rng";

export class CryptoRng extends BaseRng {

    protected getNext(): number {
        const tmpHold = new Uint32Array(1);
        crypto.getRandomValues(tmpHold);
        return tmpHold[0] / (2 ** 32);
    }

}