import { LitElement, html, css, PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('hexakai-game-rules')
class GameRules extends LitElement {
    static styles = css`
        #game-rules-preview {
            width: 100%;
        }

        .game-rules-preview {
            float: left;
            width: 250px !important;
        }

        #game-rules-preview-mobile {
            display: none;
        }

        #rules-description-container {
            min-height: 320px;
            margin-bottom: 2em;
        }

        #rules-description-container:after {
            float: reset;
        }

        @media screen and (max-width: 900px) {
            .game-rules-preview {
                float: none;
                width: 100% !important;
            }

            #rules-description-container {
                height: auto;
                margin-bottom: 0;
            }

            #game-rules-preview-non-mobile {
                display: none;
            }

            #game-rules-preview-mobile {
                display: block;
            }
        }
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        return html`
            <style>
                ${GameRules.styles}
            </style>
            <div id="rules-description-container">
                <hexakai-board id="game-rules-preview-non-mobile" class="game-rules-preview" mode="display" style="--hex-height: 60px;" board="102210021"></hexakai-board>
                <p>
                    The game is comprised of a series of rows and diagonals. Unlike Sudoku, where each cell belongs to one
                    column, each cell belongs to two diagonals, one from the top left to the bottom right, and one from the top
                    right to the bottom left. Each diagonal must have every value at least once with no duplicates, and each row
                    must also have no duplicates. In a game of size 10, for example,
                    each diagonal would have values <span class="hex-value-0"></span>-<span class="hex-value-9"></span> exactly
                    once, and the middle row must also have values
                    <span class="hex-value-0"></span>-<span class="hex-value-9"></span> at least once.
                    Every other row is smaller, so they cannot
                    contain all values, but they must not contain duplicates.
                    Let's take a look at the example board here which
                    represents a completed game of size 3:
                </p>
                <hexakai-board id="game-rules-preview-mobile" class="game-rules-preview" mode="display" style="--hex-height: 60px;" board="102210021"></hexakai-board>
                <p>
                    The values in this game range from <span class="hex-value-0"></span>
                    to <span class="hex-value-2"></span>. The middle row has all of those values exactly once and the other
                    rows don't contain any duplicates. Looking at the diagonals from the top left to the bottom right, the
                    values are <span class="hex-value-1"></span><span class="hex-value-2"></span><span class="hex-value-0"></span>,
                    <span class="hex-value-0"></span><span class="hex-value-1"></span><span class="hex-value-2"></span>,
                    and <span class="hex-value-2"></span><span class="hex-value-0"></span><span class="hex-value-1"></span>,
                    all of which satisfy the rules. The diagonals in the other direction are 
                    <span class="hex-value-1"></span><span class="hex-value-0"></span><span class="hex-value-2"></span>,
                    <span class="hex-value-2"></span><span class="hex-value-1"></span><span class="hex-value-0"></span>,
                    and <span class="hex-value-0"></span><span class="hex-value-2"></span><span class="hex-value-1"></span>,
                    which also satisfy the rules.
                </p>
                <p>Note that you can change the settings to display different values. By default, the values are 
                    0-9 plus A-F for larger boards, but you can change the settings to display 10-16 instead, or display all letters, or a variety of other options.</p>
            </div>
        `;
    }

    protected updated(changedProperties: PropertyValues): void {
        super.updated(changedProperties);
        window.dispatchEvent(new CustomEvent('update-hex-values'));
    }
}

