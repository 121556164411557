import { ShufflerParams, ShufflerType } from "../models/shuffler-params";
import { BaseShuffler } from "./shuffler";
import { ListClusterShuffler } from "./shufflers/list-cluster.shuffler";
import { FisherYatesShuffler } from "./shufflers/fisher-yates.shuffler";
import { HexDiagonalAlternationShuffler } from "./shufflers/hex-diagonal-alternation.shuffler";
import { HexCheckerShuffler } from "./shufflers/hex-checker.shuffler";
import { HexEmptyCenterShuffler } from "./shufflers/hex-empty-center.shuffler";
import { GlobalAccessService } from "../global-access-service/global-access-service";

export class ShufflerFactory {

    private instances = new Map<string, BaseShuffler<any>>();

    constructor() {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            shufflerFactory: {
                get: this.getNew.bind(this)
            }
        });
    }

    get<T>(params: ShufflerParams): BaseShuffler<T> {
        // TODO: this is hacky, may lead to defects
        const key = JSON.stringify(params);
        if (!this.instances.has(key)) {
            this.instances.set(key, this.getNew(params));
        }

        return this.instances.get(key)!;
    }

    getNew<T>(params: ShufflerParams): BaseShuffler<T> {
        switch (params.shufflerType) {
            case ShufflerType.default:
                return new FisherYatesShuffler<T>(params);
            case ShufflerType.listCluster:
                return new ListClusterShuffler<T>(params);
            case ShufflerType.hexDiagonalAlternation:
                return new HexDiagonalAlternationShuffler<T>(params);
            case ShufflerType.hexChecker:
                return new HexCheckerShuffler<T>(params);
            case ShufflerType.hexEmptyCenter:
                return new HexEmptyCenterShuffler<T>(params);
        }

        throw new Error("Unrecognized shuffler! " + params.shufflerType);
    }
}