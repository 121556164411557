import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

type TableEntry = HTMLElement | TableEntry[];

@customElement('table-of-contents')
export class TableOfContentsComponent extends LitElement {

    private headers: TableEntry[] = [];

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();

        // TODO: need to make this force refresh or refresh page will break

        document.addEventListener("DOMContentLoaded", () => {
            this.headers = this.getHeaders();
            console.debug(`[TableOfContentsComponent] headers`, this.headers);
            this.requestUpdate();
        });
    }

    render() {
        if (this.headers.length === 0) {
            this.headers = this.getHeaders();
        }

        return html`
            <style>
                .entry-primary {
                    font-weight: bold;
                }

                .entry {
                    cursor: pointer;
                }

                .entry:hover {
                    color: var(--button-hover-background-color);
                }
            </style>
            <h2>Table of Contents</h2>
            ${this.getHeaderTemplate(this.headers, 0)}
        `
    }

    private getHeaderTemplate(headers: TableEntry[], depth: number): any {
        return html`
            <ul>
            ${headers.map(header => {
                if (Array.isArray(header)) {
                    return this.getHeaderTemplate(header, depth + 1);
                } else {
                    return html`<li
                        class="entry ${depth === 0 ? "entry-primary" : ""}"
                        @click=${(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();

                            header.scrollIntoView();
                            return false;
                        }}
                    >${header.innerText}</li>`;
                }
            })}
            </ul>
        `;
    }

    private getHeaders(): TableEntry[] {
        const table: TableEntry[] = [];

        const includeHOne = [...document.querySelectorAll("h1")].length > 2;
        const headers = [...document.querySelectorAll(".toc-header")] as HTMLElement[];

        table.push(headers[0]);
        const tableRefs: any[] = [table];
        let prevLevel = parseInt(headers[0].tagName.substring(1));

        for (let i=1; i<headers.length; i++) {
            const level = parseInt(headers[i].tagName.substring(1));

            const currentLevel = tableRefs[tableRefs.length - 1];
            if (level === prevLevel) {
                // siblings
                currentLevel.push(headers[i]);
            } else if (level > prevLevel) {
                // header is a child
                currentLevel.push([headers[i]]);
                tableRefs.push(currentLevel[currentLevel.length - 1]);
            } else {
                // header is up one level
                tableRefs.pop();
                tableRefs[tableRefs.length - 1].push(headers[i]);
            }

            prevLevel = level;
        }

        return table;
    }
}