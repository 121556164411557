import { HexakaiBoardAssignment } from "../models/hexakai-board-state";
import { HexakaiBoardData } from "./hexakai-board-data";
import { HexakaiBoardStateVisitor } from "./hexakai-board-state-visitor";

export class HexakaiBoardValidator {

    public static validate(board: HexakaiBoardAssignment): void {
        const boardData = new HexakaiBoardData(board.gameSize);

        const boardVisitor = new HexakaiBoardStateVisitor(board);

        // ensure each row has no duplicates
        let visitSet = new Set<string>();
        for (let i=0; i<boardData.getNumRows(); i++) {
            // ensure the row is the correct size
            const rowSize = boardData.getNumCols(i);
            if (board.cells[i].length !== rowSize) {
                throw `Row ${i} is not the right size: ${board.cells[i].length} vs ${rowSize}`;
            }

            visitSet.clear();
            for (const [value] of boardVisitor.visitRowForward(i, 0, false)) {
                if (visitSet.has(value)) {
                    throw `Row ${i} has a duplicate value ${value}`;
                }

                if (!value || value === ".") {
                    throw `Row ${i} has a missing value!`;
                }
                visitSet.add(value);
            }
        }

        // ensure each column has no duplicates
        for (let i=0; i<=boardData.getLargestRowIndex(); i++) {
            visitSet.clear();
            for (const [value] of boardVisitor.visitColLeftForward(i, i, false)) {
                if (visitSet.has(value)) {
                    throw `Column left from ${i} has a duplicate value ${value}`;
                }

                if (!value || value === ".") {
                    throw `Column ${i} has a missing value!`;
                }
                visitSet.add(value);
            };

            visitSet.clear();
            for (const [value] of boardVisitor.visitColRightForward(i, 0, false)) {
                if (visitSet.has(value)) {
                    throw `Column right from ${i} has a duplicate value ${value}`;
                }

                if (!value || value === ".") {
                    throw `Column ${i} has a missing value!`;
                }
                visitSet.add(value);
            };
        }
    }

    public static isValid(board: HexakaiBoardAssignment): boolean {
        try {
            this.validate(board);
            return true;
        } catch (ex) {
            return false;
        }
    }

}