export enum HexakaiGameActionType {
    value = "value",
    color = "color",
    pencil = "pencil"
}

export interface HexakaiGameAction {
    type: HexakaiGameActionType;
    row: number;
    col: number;
    oldValue: string;
    newValue: string;
}