import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { UserMetrics } from '../models/user-metrics';
import { SessionService } from '../session-service/session-service';
import { ClientService } from '../client-service/client-service';
import { ModalService } from '../modal-service/modal-service';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { EventListenerEventsService } from '../user-events/event-listener-events-service';
import { HexakaiGameDifficulties } from '../models/hexakai-game-params';

@customElement('hexakai-gameplay-view')
class GameplayView extends LitElement {
    static styles = css`
        .stats-table {
            width: 100%;
            margin: 1rem auto;
            border-collapse: collapse;
        }

        .stats-table tr {
            border-bottom: 1px solid #ddd;
        }

        .stats-table tr:first-child {
            border-top: 1px solid #ddd;
        }

        .stats-table td {
            padding: 1.25rem 1rem;
        }

        /* Label column */
        .stats-table-two td:first-child {
            text-align: left;
            width: 70%;
        }

        /* Value column */
        .stats-table-two td:last-child {
            text-align: right;
            width: 30%;
        }

        /* Header */
        .stats-header {
            text-align: center;
            padding: 1.25rem 0;
        }

        .table-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1em;
        }

        @media screen and (max-width: 1300px) {
            .table-container {
                grid-template-columns: unset;
                grid-template-rows: repeat(2, 1fr);
            }
        }

        @media screen and (max-width: 500px) {
            .stats-table-detailed td {
                padding: 0.7rem 0.6rem;
            }
        }

        @media screen and (max-width: 400px) {
            .stats-table-detailed td {
                padding: 0.5rem 0.2rem !important;
            }
        }
    `;

    private metrics!: UserMetrics;
    private clientService = new ClientService();
    private modalService = new ModalService();
    private storageService = new LocalStorageService();
    private eventListenerEventsService = new EventListenerEventsService();

    private sessionService = new SessionService(
        this.clientService,
        this.modalService,
        this.storageService,
        this.eventListenerEventsService
    );

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.sessionService.getUserMetrics().then(metrics => {
            this.metrics = metrics;
            this.requestUpdate();
        })
    }

    render() {
        if (!this.metrics) {
            return html`<loading-display>Loading...</loading-display>`;
        }

        const gamesStarted = Object.entries(this.metrics.boards).reduce((count, [size, difficulties]) => {
            return count + Object.entries(difficulties).reduce((subCount, [difficulty, values]) => {
                return subCount + values.numStarted;
            }, 0);
        }, 0);

        const gamesCompleted = Object.entries(this.metrics.boards).reduce((count, [size, difficulties]) => {
            return count + Object.entries(difficulties).reduce((subCount, [difficulty, values]) => {
                return subCount + values.numCompleted;
            }, 0);
        }, 0);

        return html`
            <style>
                ${GameplayView.styles}
            </style>
            <h2>Your Stats</h2>
            <p>These tables show the number of games you've started and completed.</p>

            <div class="table-container">
                <div class="table-with-content">
                    <h3>Your Puzzles</h3>
                    <table class="stats-table stats-table-two">
                        <tr>    
                            <td><b>Puzzles Started:</b></td>
                            <td>${gamesStarted}</td>
                        </tr>
                        <tr>
                            <td><b>Puzzles Completed:</b></td>
                            <td>${gamesCompleted}</td>
                        </tr>
                    </table>
                </div>

                <div class="table-with-content">
                    <h3>Your Daily Puzzles</h3>
                    <table class="stats-table stats-table-two">
                        <tr>
                            <td><b>Puzzles Started:</b></td>
                            <td>${this.metrics.dailyPuzzles.numStarted}</td>
                        </tr>
                        <tr>
                            <td><b>Puzzles Completed:</b></td>
                            <td>${this.metrics.dailyPuzzles.numCompleted}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <h2>Your Streaks</h2>
            <p>These tables show how many days in a row you've completed games and daily puzzles.</p>
            <div class="table-container">
                <div class="table-with-content">
                    <h3>Puzzles</h3>
                    <table class="stats-table stats-table-two">
                        <tr>
                            <td><b>Current Streak:</b></td>
                            <td>${this.metrics.streaks.boardCompleted.numDays}</td>
                        </tr>
                        <tr>
                            <td><b>Best Streak:</b></td>
                            <td>${this.metrics.streaks.boardCompleted.longestStreak}</td>
                        </tr>
                    </table>
                </div>
                <div class="table-with-content">
                    <h3>Daily Puzzles</h3>
                    <table class="stats-table stats-table-two">
                        <tr>
                            <td><b>Current Streak:</b></td>
                            <td>${this.metrics.streaks.dailyBoardCompleted.numDays}</td>
                        </tr>
                        <tr>
                            <td><b>Best Streak:</b></td>
                            <td>${this.metrics.streaks.dailyBoardCompleted.longestStreak}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <h2>Detailed Stats</h2>
            <p>This table shows how many you've completed of each size and difficulty.
            <table class="stats-table stats-table-detailed">
                <tr>
                    <td><b>Board Size</b></td>
                    ${HexakaiGameDifficulties.map(diff => html`<td><b>${diff.substring(0, 1).toUpperCase() + diff.substring(1)}</b></td>`)}
                </tr>
                ${Object.entries(this.metrics.boards).map(([size, data]) => {
                    return html`
                        <tr>
                            <td><b>Size ${size}</b>:</td>
                            ${Object.entries(data).map(([diff, diffData]) => {
                                return html`<td>${diffData.numCompleted}</td>`
                            })}
                        </tr>
                    `;
                })}
                </tr>
            </table>
        `;
    }
}

