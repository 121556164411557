import { RngParams } from "./rng-params";

export enum ShufflerType {
    default = "fischerYates",
    listCluster = "listCluster",
    hexDiagonalAlternation = "hexDiagonalAlternation",
    hexChecker = "hexChecker",
    hexEmptyCenter = "hexEmptyCenter"
}

export interface ShufflerParams {
    shufflerType: ShufflerType;
    rngParams?: RngParams;
    data?: any;
    seed?: string | number;
}