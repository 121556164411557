import { RngParams } from "../models/rng-params";

export abstract class BaseRng {

    constructor(
        rngParams: RngParams
    ) {}

    getRandom(): number {
        return this.getNext();
    }

    protected abstract getNext(): number;

    protected stringToNumber(str: string): number {
        let hash = 0;
        
        // Generate a simple hash from the string
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash); // Hashing formula
            hash = hash & hash; // Convert to 32-bit integer
        }
    
        // Use Math.abs to avoid negative values and normalize the hash to [0, 1]
        const normalized = Math.abs(hash) / 2 ** 31; // Normalize within the range [0, 1]
        
        return normalized;
    }
}