import { RngParams, RngType } from "../models/rng-params";
import { BaseRng } from "./rng";
import { LogisticMapRng } from "./rngs/logistic-map.rng";
import { JsMathRng } from "./rngs/js-math.rng";
import { MkAleaRng } from "./rngs/mk-alea.rng";
import { FractionalRng } from "./rngs/fractional.rng";
import { CryptoRng } from "./rngs/crypto.rng";
import { RootRatioRng } from "./rngs/root-ratio.rng";
import { CircleRng } from "./rngs/circle.rng";
import { XorshiftRng } from "./rngs/xor-shift.rng";
import { CollatzRng } from "./rngs/collatz.rng";
import { GeometricRatioRng } from "./rngs/geometric-ratio.rng";
import { GlobalAccessService } from "../global-access-service/global-access-service";


export class RngFactory {

    private instances = new Map<string, BaseRng>();

    constructor() {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            rngFactory: {
                get: this.getNew.bind(this)
            }
        });
    }

    get(params: RngParams): BaseRng {
        // TODO: this is hacky, may lead to defects
        const key = JSON.stringify(params);
        if (!this.instances.has(key)) {
            this.instances.set(key, this.getNew(params));
        }

        return this.instances.get(key)!;
    }

    getNew(params: RngParams): BaseRng {
        switch (params.rngtype) {
            case RngType.default:
                return new JsMathRng(params);
            case RngType.mkAlea:
                return new MkAleaRng(params);
            case RngType.logisticMap:
                return new LogisticMapRng(params);
            case RngType.fractional:
                return new FractionalRng(params);
            case RngType.crypto:
                return new CryptoRng(params);
            case RngType.rootRatio:
                return new RootRatioRng(params);
            case RngType.circle:
                return new CircleRng(params);
            case RngType.xorShift:
                return new XorshiftRng(params);
            case RngType.collatz:
                return new CollatzRng(params);
            case RngType.geometricRatio:
                return new GeometricRatioRng(params);
        }

        throw new Error("Unrecognized rng! " + params.rngtype);
    }
}