import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('img-smart')
class ImgSmart extends LitElement {
    @property({ type: String })
    src = '';

    static styles = css`
        :host {
            display: block;
            position: relative;
        }

        .spinner-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: min(30px, 50%);
            height: min(30px, 50%);
        }

        .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-top: 4px solid #000;
            border-radius: 50%;
            width: min(30px, 50%);
            height: min(30px, 50%);
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        img {
            display: block;
            max-width: 100%;
            width: var(--width, 100%);
            height: var(--height, auto);
            max-width: var(--max-width);
            max-height: var(--max-height);
        }

        .background {
            display: block;
            width: 100%;
            height: 100%;
            background-color: lightgray;
            position: absolute;
            top: 0;
            left: 0;
        }
    `;

    firstUpdated() {
        const img = this.shadowRoot!.querySelector('img') as HTMLImageElement;
        if (img.complete) {
            this.handleImageLoad();
        }
    }

    render() {
        return html`
            <div class="background"></div>
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
            <img src="${this.src}" @load="${() => this.handleImageLoad()}" @error="${(err: any) => this.handleImageError(err)}" />
        `;
    }

    private handleImageLoad() {
        const spinner = this.shadowRoot!.querySelector('.spinner') as HTMLElement;
        const background = this.shadowRoot!.querySelector('.background') as HTMLElement;
        spinner.style.display = 'none';
        background.style.display = 'none';
    }

    private handleImageError(err: any) {
        /*const spinner = this.shadowRoot!.querySelector('.spinner') as HTMLElement;
        const background = this.shadowRoot!.querySelector('.background') as HTMLElement;
        spinner.style.display = 'none';
        background.style.display = 'none';*/
        console.error('[ImgSmart] Image failed to load', err);
    }
}

