import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class FractionalRng extends BaseRng {

    private increasing = Math.random() < 0.5;
    private f = 2;
    private n: number;

    constructor(params: RngParams) {
        super(params);
        if (params.data?.f) {
            this.f = Math.max(2, Math.round(params.data.f));
        }

        if (params.data?.increasing !== null) {
            this.increasing = params.data.increasing;
        }

        if (params.seed) {
            let seed = typeof params.seed === 'string'  
                ? this.stringToNumber(params.seed) * this.f
                : params.seed;

            seed %= this.f;
            this.n = Math.round(seed);
        }

        this.n = this.increasing ? -1 : this.f;
    }

    protected getNext(): number {
        if (this.increasing) {
            this.n = (this.n + 1) % this.f;
        } else {
            this.n -= 1;
            if (this.n < 0) {
                this.n += this.f;
            }
        }

        return this.n / this.f;
    }
    
}