import { ClientDispatcherService } from "../client-dispatcher-service/client-dispatcher-service";

export class WindowFullscreenService {

    private static instance: WindowFullscreenService;

    constructor(
        // FOR NOW: use this directly, we can use strategy in the future if needed
        private clientDispatcherService: ClientDispatcherService
    ) {
        if (WindowFullscreenService.instance) {
            return WindowFullscreenService.instance;
        }

        WindowFullscreenService.instance = this;
    }

    async toggleFullscreen(): Promise<void> {
        const isFullscreen = await this.isFullscreen();
        this.setFullscreen(!isFullscreen);
    }

    async setFullscreen(isFullscreen: boolean): Promise<void> {
        if (isFullscreen) {
            // Enter fullscreen if not already
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen().catch(err => {
                    console.error(`[WindowFullscreenService] Failed to enable fullscreen: ${err.message}`);
                });
            }
        } else {
            // Exit fullscreen if in fullscreen mode
            if (document.fullscreenElement) {
                document.exitFullscreen().catch(err => {
                    console.error(`[WindowFullscreenService] Failed to exit fullscreen: ${err.message}`);
                });
            }
        }
    }

    async isFullscreen(): Promise<boolean> {
        return !!document.fullscreenElement;
    }

}