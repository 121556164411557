import { HexakaiBoardData } from "../../hexakai-board/hexakai-board-data";
import { HexakaiBoardStateVisitor } from "../../hexakai-board/hexakai-board-state-visitor";
import { ShufflerParams } from "../../models/shuffler-params";
import { getRandom } from "../get-random";
import { BaseShuffler } from "../shuffler";

export class HexDiagonalAlternationShuffler<T> extends BaseShuffler<T> {

    private skip = 1;
    private gameSize = 10;
    private type!: "diagonal-left" | "diagonal-right" | "row";
    private rowColMap: number[][] = [];
    private visitor: HexakaiBoardStateVisitor<any>;
    private seed: any;

    constructor(
        params: ShufflerParams
    ) {
        super(params);
        if (params.seed) {
            this.seed = params.seed;
        }
        
        if (!params.data?.gameSize) {
            throw new Error("GameSize required in params!")
        }
        this.gameSize = params.data.gameSize;
        if (params.data?.type) {
            this.type = params.data.type;
        }
        if (params.data?.skip) {
            this.skip = params.data.skip;
        }

        // put reverse map together
        let index = 0;
        const boardState = new HexakaiBoardData(this.gameSize).createBoardState((row, col) => {
            if (row === this.rowColMap.length) {
                this.rowColMap.push([]);
            }
            this.rowColMap[row].push(index);
            index ++;
        });
        this.visitor = new HexakaiBoardStateVisitor(boardState);
    }

    protected doShuffle(input: T[]): void {
        if (getRandom(this.seed) < 0.5) {
            input.reverse();
        }

        const type = this.type || ["diagonal-left", "diagonal-right"/*, "row"*/]
                                    [Math.floor(getRandom(this.seed) * 2)] as any;

        let rows: number[];
        let cols: number[];
    
        switch (type) {
            case "row":
                rows = new Array(this.gameSize * 2 - 1).fill(0).map((_, ind) => ind);
                cols = new Array(this.gameSize * 2 - 1).fill(0);
                break;
            case "diagonal-left":
                rows = new Array(this.gameSize).fill(0).map((_, ind) => ind);
                cols = new Array(this.gameSize).fill(0).map((_, ind) => ind);
                break;
            default:
                rows = new Array(this.gameSize).fill(0).map((_, ind) => ind);
                cols = new Array(this.gameSize).fill(0);
                break;
        }
    
        let index = 0;
    
        // First phase: Process rows that fit the modulus pattern
        for (let i = 0; i < rows.length; i++) {
            if ((i + 1) % (this.skip + 1) === 0) {
                index = this.handleRow(type, rows[i], cols[i], input, index);
            }
        }
    }
    
    private handleRow(
        type: string,
        row: number,
        col: number,
        input: T[],
        index: number
    ): number {
        let iterable: IterableIterator<[T, number, number]>;
    
        switch (type) {
            case "row":
                iterable = this.visitor.visitRowForward(row, col, false);
                break;
            case "diagonal-left":
                iterable = this.visitor.visitColLeft(row, col);
                break;
            default:
                iterable = this.visitor.visitColRight(row, col);
                break;
        }
    
        for (const [_, rowIdx, colIdx] of iterable) {
            const newIndex = this.rowColMap[rowIdx][colIdx];
            // Swap in input
            const tmp = input[index];
            input[index] = input[newIndex];
            input[newIndex] = tmp;

            index++;
        }
    
        return index;
    }
    
}