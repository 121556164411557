import { html } from "lit";
import { ClientService } from "../client-service/client-service";
import { ModalService } from "../modal-service/modal-service";

export class ShareService {

    private static instance: ShareService;

    constructor(
        private clientService: ClientService,
        private modalService: ModalService
    ) {
        if (ShareService.instance) {
            return ShareService.instance;
        }

        ShareService.instance = this;
    }

    share(title: string, text: string, url: string, modalTitle="Share"): void {
        if (this.clientService.getConfig().share.navigatorEnabled) {
            this.shareNavigator(title, text, url).catch(err => {
                console.warn("[ShareService] unable to share via navigator", {
                    error: err,
                    message: err?.toString?.() || `${err}`
                });
                this.shareModal(title, text, url, modalTitle);
            })
        } else {
            this.shareModal(title, text, url, modalTitle);
        }
    }

    private async shareNavigator(title: string, text: string, url: string): Promise<any> {
        return navigator.share({
            title,
            text,
            url
        });
    }

    private shareModal(title: string, text: string, url: string, modalTitle: string): void {
        const modalId = "share-modal";

        const contentTemplate = html`
            <hr style="width: 90%">
            <non-navigator-share
                style="max-width: 100%"
                .title=${title}
                .text=${text}
                .url=${url}
            ></non-navigator-share>
        `;

        this.modalService.showModal(modalId, modalTitle, contentTemplate);
    }
}