import { StorageService } from "../models/storage-service";

export class StorageMessageReceiverService {

    constructor(
        private subject: any,
        private storageService: StorageService
    ) {
        this.init();
    }

    private init(): void {
        this.subject.addEventListener('message', (e: any) => {
            const data = e.detail || e.data || e;
            console.debug(`[StorageMessageReceiverService] receiving`, data);

            switch (data?.type) {
                case "local-storage-get":
                    this.storageService.get(data.data.key)
                        .then((res) => this.sendResponse(data.id, data.type, res, null))
                        .catch((err) => this.sendResponse(data.id, data.type, null, err))
                    break;
                case "local-storage-set":
                    this.storageService.set(data.data.key, data.data.value)
                        .then((res) => this.sendResponse(data.id, data.type, res, null))
                        .catch((err) => this.sendResponse(data.id, data.type, null, err));
                    break;
                case "local-storage-remove":
                    this.storageService.remove(data.data.key)
                        .then((res) => this.sendResponse(data.id, data.type, res, null))
                        .catch((err) => this.sendResponse(data.id, data.type, null, err));
                    break;
            }
        })
    }

    private sendResponse(id: number, type: string, data: any, error: any): void {
        const send = {
            id,
            type,
            data,
            error
        };
        console.log(`[StorageMessageReceiverService] dispatching`, send)

        if (this.subject.postMessage) {
            this.subject.postMessage(send)
        } else {
            this.subject.dispatchEvent(new CustomEvent('message', {
                detail: send
            }))
        }
    }
}