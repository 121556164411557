import { mkAlea } from "@spissvinkel/alea";
import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class MkAleaRng extends BaseRng {

    private mkAlea: any;

    constructor(params: RngParams) {
        super(params);
        this.mkAlea = mkAlea(`${params.seed}`).random;
    }
    
    protected getNext(): number {
        return this.mkAlea();
    }

}