import { HexakaiBoardData } from "../../hexakai-board/hexakai-board-data";
import { HexakaiBoardStateVisitor } from "../../hexakai-board/hexakai-board-state-visitor";
import { ShufflerParams } from "../../models/shuffler-params";
import { getRandom } from "../get-random";
import { BaseShuffler } from "../shuffler";

export class HexCheckerShuffler<T> extends BaseShuffler<T> {

    private gameSize = 10;
    private boardData: HexakaiBoardData;
    private visitor: HexakaiBoardStateVisitor<any>;
    private rowColMap: number[][] = [];
    private seed: any;

    constructor(
        params: ShufflerParams
    ) {
        super(params);
        if (params.seed) {
            this.seed = params.seed;
        }

        if (!params.data?.gameSize) {
            throw new Error("GameSize required in params!")
        }
        this.gameSize = params.data.gameSize;

        // put reverse map together
        this.boardData = new HexakaiBoardData(this.gameSize);
        let index = 0;
        const boardState = this.boardData.createBoardState((row, col) => {
            if (row === this.rowColMap.length) {
                this.rowColMap.push([]);
            }
            this.rowColMap[row].push(index);
            index ++;
        });
        this.visitor = new HexakaiBoardStateVisitor(this.boardData.createBoardState(0));

    }
    
    protected doShuffle(input: T[]): void {
        if (getRandom(this.seed) < 0.5) {
            input.reverse();
        }

        const startRow = getRandom(this.seed) < 0.5 ? 0 : 1;

        let isOdd = startRow === 1
            ? getRandom(this.seed) < 0.5
            : false;

        let index = 0;
        for (let row=startRow; row<this.boardData.getNumRows(); row+=2) {
            for (const [_, __, col] of this.visitor.visitRowForward(row, 0, false)) {
                isOdd != isOdd;
                if (isOdd !== (col % 2 === 0)) {
                    continue;
                }

                const newIndex = this.rowColMap[row][col];
                
                const tmp = input[index];
                input[index] = input[newIndex];
                input[newIndex] = tmp;
    
                index++;
            }
        }
    }
}