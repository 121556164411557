import { ShufflerParams } from "../../models/shuffler-params";
import { getRandom } from "../get-random";
import { BaseShuffler } from "../shuffler";

export class ListClusterShuffler<T> extends BaseShuffler<T> {
    
    private numClusters = 2;
    private isEvenFocal?: boolean;
    private seed: any;

    constructor(params: ShufflerParams) {
        super(params);

        if (params.data?.numClusters) {
            this.numClusters = params.data.numClusters;
        }
        if (params.seed) {
            this.seed = params.seed;
        }

        if (params.data?.evenFocal !== null) {
            this.isEvenFocal = params.data.evenFocal;
        }
    }

    protected doShuffle(input: T[]): void {
        const originalInput = [...input];

        // determine focal points, then alternate for enabled / disabled
        const isEvenFocal = typeof this.isEvenFocal !== 'undefined'
            ? this.isEvenFocal
            : getRandom(this.seed) < 0.5;

        const numFocalPoints = isEvenFocal
            ? this.numClusters*2+1
            : this.numClusters*2-1;

        const clusterFocalPoints: number[] = [];
        for (let i=0; i<numFocalPoints; i++) {
            if (i%2===0 === isEvenFocal) {
                continue;
            }

            clusterFocalPoints.push(Math.floor(i/numFocalPoints*input.length));
        }
        
        const assignedIndices = new Set<number>();
        // assign each cluster point
        for (let i=0; i<clusterFocalPoints.length; i++) {
            if (assignedIndices.has(clusterFocalPoints[i])) {
                continue;
            }

            input[i] = originalInput[clusterFocalPoints[i]];
            assignedIndices.add(clusterFocalPoints[i]);
        }

        // branch out and continue assigning left right pairs
        let inputIndex = clusterFocalPoints.length;
        let inc=1;
        while (assignedIndices.size < input.length) {
            for (let j=0; j<clusterFocalPoints.length; j++) {
                // assign left and right
                const leftIndex = clusterFocalPoints[j] - inc;
                const rightIndex = clusterFocalPoints[j] + inc;

                if (leftIndex > -1 && !assignedIndices.has(leftIndex)) {
                    input[inputIndex] = originalInput[leftIndex];
                    inputIndex++;
                    assignedIndices.add(leftIndex);
                }
                
                if (rightIndex < input.length && !assignedIndices.has(rightIndex)) {
                    input[inputIndex] = originalInput[rightIndex];
                    inputIndex++;
                    assignedIndices.add(rightIndex);
                }
            }

            inc ++;
        }
    }
}