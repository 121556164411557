import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { DIFFICULTY_TEXTS } from '../app-texts/difficulty-texts';
import { HexakaiGameDifficulty } from '../models/hexakai-game-params';

@customElement('hexakai-difficulty-levels')
class DifficulytLevels extends LitElement {
    static styles = css``;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        return html`
            <ul>
                <li><b>Easy:</b> ${DIFFICULTY_TEXTS[HexakaiGameDifficulty.easy]}</li>
                <li><b>Medium:</b> ${DIFFICULTY_TEXTS[HexakaiGameDifficulty.medium]}</li>
                <li><b>Difficult:</b> ${DIFFICULTY_TEXTS[HexakaiGameDifficulty.difficult]}</li>
                <li><b>Ultra-Difficult:</b> ${DIFFICULTY_TEXTS[HexakaiGameDifficulty.ultraDifficult]}</li>
            </ul>
            <p>
                The board size significantly contributes to the overall difficulty of the game, as well as the time it takes to complete
                it. For example, a board of size 16 on easy mode
                will be much more difficult than a board of size 5 on difficult mode, and will take much longer to complete. A
                board of size 10 on ultra difficult gives
                a nice balance between board size and difficulty level, resulting in a challenging but manageable board
                that can be completed in one session.
                A board of size 14 or higher on ultra difficult mode will take significantly longer to complete and
                require much more strategic thinking and patience.
            </i></p>
        `;


    }
}

