import { HexakaiGameDifficulty } from "../models/hexakai-game-params";
import { HexakaiGameSession, HexakaiGameSessionCompressed } from "../models/hexakai-game-session";

export interface HexakaiGameSessionSerializerParams {
    includeFilledColors: boolean;
    includeFilledValues: boolean;
    includePencilValues: boolean;
}

export class HexakaiGameSessionSerializer {

    /**
     * Serialize the game session to a string
     */
    static serialize(session: HexakaiGameSession, params: HexakaiGameSessionSerializerParams): string {
        // clone the session and make modifications to board state
        const clonedSession: HexakaiGameSession = JSON.parse(JSON.stringify(session));
        // for board state, set numbers to lowercase letters and increment actual letters
        // if disabled, make it capital
        for (let row = 0; row < clonedSession.boardState!.cells.length; row++) {
            for (let col = 0; col < clonedSession.boardState!.cells[row].length; col++) {
                const disabledIncludes = clonedSession.disabledCells![row].includes(col);
                if (!params.includeFilledValues && !disabledIncludes) {
                    clonedSession.boardState!.cells[row][col] = "";
                } else {
                    let newChar = this.mapCharCompressed(clonedSession.boardState!.cells[row][col]);
                    if (disabledIncludes) {
                        newChar = newChar.toUpperCase();
                    }
                    clonedSession.boardState!.cells[row][col] = newChar;
                }
            }
        }

        const compressed = this.mapToCompressed(clonedSession);
        if (!params.includeFilledColors) {
            compressed[4] = void 0;
        }

        if (!params.includePencilValues) {
            compressed[5] = void 0;
        }

        // remove array marks
        return compressed.join("!");
    }

    public static deserialize(str: string): HexakaiGameSession {
        const raw = str.split("!");
        const dailyPuzzleTimestamp = parseInt(raw[0]);
        const difficultyNumber = parseInt(raw[1]);
        const gameSize = parseInt(raw[2]);
        const mappedState = raw[3];
        const colors = raw[4] || null;

        // parse the board state, recording which cells are disabled
        const cells: string[][] = [];
        const disabled: number[][] = [];

        const splitRows = mappedState.split("+");
        for (let row = 1; row < splitRows.length; row++) {
            cells.push([]);
            disabled.push([]);
            for (let col = 0; col < splitRows[row].length; col++) {
                const char = splitRows[row][col];
                if (char === "|") {
                    cells[row-1].push("");
                } else {
                    const adjChar = char.toLowerCase();
                    if (adjChar !== char) {
                        disabled[row-1].push(col);
                    }
                    const original = this.reverseMapCharCompressed(adjChar).toUpperCase();
                    cells[row-1].push(original);
                }
            }
        }

        // TODO: handle colors
        const deserialized = {
            params: {
                gameSize,
                difficulty: this.reverseMapDifficulty(difficultyNumber)
            },
            boardState: {
                gameSize,
                cells
            },
            disabledCells: disabled,
            dailyPuzzleTimestamp
        };

        console.log("[HexakaiGameSessionSerializer] deserialized session", deserialized);
        return deserialized;
    }

    private static mapToCompressed(session: HexakaiGameSession): HexakaiGameSessionCompressed {
        return [
            session.dailyPuzzleTimestamp || 0,
            this.mapDifficulty(session.params.difficulty),
            session.params.gameSize,
            this.stringifyTable(session.boardState?.cells || []),
            this.stringifyTable(session.cellColors || []),
            this.stringify3dTable(session.pencilMarks || [])
        ]
    }

    /**
     * Map the difficulty to a number
     */
    private static mapDifficulty(difficulty: HexakaiGameDifficulty): number {
        switch (difficulty) {
            case HexakaiGameDifficulty.easy:
                return 0;
            case HexakaiGameDifficulty.medium:
                return 1;
            case HexakaiGameDifficulty.difficult:
                return 2;
            default: return 3;
        }
    }

    /**
     * Map the difficulty to a number
     */
    private static reverseMapDifficulty(difficulty: number): HexakaiGameDifficulty {
        switch (difficulty) {
            case 0:
                return HexakaiGameDifficulty.easy;
            case 1:
                return HexakaiGameDifficulty.medium;
            case 2:
                return HexakaiGameDifficulty.difficult;
            default:
                return HexakaiGameDifficulty.ultraDifficult;
        }
    }

    /**
     * Stringify a table
     * Assumes all 2nd level items items are strings with one char or numbers with one digit
     * Assumes no z
     * Will replace empty items with an x
     */
    private static stringify3dTable(table: (string | number)[][][]): string {
        let str = "";
        for (const row of table) {
            str += "+" + this.stringifyTable(row)
        }
        return str;
    }

    /**
     * Stringify a table
     * Assumes all 2nd level items items are strings with one char or numbers with one digit
     * Assumes no z
     * Will replace empty items with an x
     */
    private static stringifyTable(table: (string | number)[][]): string {
        let str = "";
        for (const row of table) {
            str += "+" + this.stringifyRow(row)
        }
        return str;
    }

    /**
     * Stringify a row
     * Assumes all items are strings with one char or numbers with one digit
     * Will replace empty items with a "|"
     */
    private static stringifyRow(row: (string | number)[]): string {
        let str = "";
        for (const item of row) {
            str += `${item}`.length === 0
                ? "|"
                : item;
        }

        return str;
    }

    private static mapCharCompressed(inputChar: string): string {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';

        // Helper function to map digits to nth lowercase English letter
        function digitToLetter(digit: string): string {
            const n = parseInt(digit, 10);
            return alphabet[n];
        }

        // Helper function to map letters to (n+10)th lowercase English letter
        function letterToAdjustedLetter(letter: string): string {
            const index = alphabet.indexOf(letter.toLowerCase());
            if (index === -1) {
                return letter; // If not found, return the original character
            }
            const newIndex = (index + 10) % 26;
            return alphabet[newIndex];
        }

        // Determine if the inputChar is a digit or a letter and apply the respective mapping
        if (/[0-9]/.test(inputChar)) {
            return digitToLetter(inputChar);
        } else if (/[a-zA-Z]/.test(inputChar)) {
            return letterToAdjustedLetter(inputChar);
        } else {
            return inputChar; // Return non-alphanumeric characters unchanged
        }
    }

    private static reverseMapCharCompressed(inputChar: string): string {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';

        // Helper function to map nth lowercase English letter to a digit
        function letterToDigit(letter: string): string {
            const index = alphabet.indexOf(letter);
            return index.toString();
        }

        // Helper function to map (n+10)th lowercase English letter back to the original letter
        function adjustedLetterToLetter(letter: string): string {
            const index = alphabet.indexOf(letter.toLowerCase());
            if (index === -1) {
                return letter; // If not found, return the original character
            }
            const newIndex = (index - 10 + 26) % 26;
            return alphabet[newIndex];
        }

        // Determine if the inputChar is a letter and apply the respective reverse mapping
        if (/[a-z]/.test(inputChar)) {
            // Check if it is one of the first ten letters of the alphabet
            const index = alphabet.indexOf(inputChar);
            if (index >= 0 && index < 10) {
                return letterToDigit(inputChar);
            } else {
                return adjustedLetterToLetter(inputChar);
            }
        } else {
            return inputChar; // Return non-lowercase-letter characters unchanged
        }
    }
}