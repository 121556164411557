import { html } from "lit";
import { ClientService } from "../client-service/client-service";
import { BLOG_DOCUMENTS } from "../scripts/blog-paths";
import { HexakaiGameDifficulty } from "../models/hexakai-game-params";
import { DIFFICULTY_TEXTS } from "../app-texts/difficulty-texts";
import { HexakaiCellValueMapper } from "../hexakai-board/hexakai-cell-value-mapper";
import { CellValueType } from "../models/settings";

const clientService = new ClientService();

export const INFOBOX_SOURCE_MESSAGES_DEFAULT = [
    `Keep track of possible cell values with our pencil marking feature! Right click or long-press a cell and select its possible values.`,
    `Drag the board around to bring different parts of it into view.`,
    `Made a mistake? Use the undo and redo buttons on the top left.`,
    {
        displayMessage: html`Did you know we have a tutorial? You can find it in the menu if you want to try it out.`,
    },
    {
        displayMessage:  html`Like puzzles? Try out the author's book <a href="https://amzn.to/44M09gA" target="_blank">Mental Mosaics</a>!`,
        modalTitle: `Enjoy Puzzles?`,
        modalButtons: [
            html`
                <styled-button @click="${() => window.open('https://amzn.to/4gMDUwu', '_blank')}">Get the Book</styled-button>
            `
        ],
        modalTemplate: html`
            <style>
                .mm-cover {
                    width: 160px;
                    aspect-ratio: 0.66;
                    float: left;

                    margin-right: 10px;
                    margin-bottom: 10px;
                }

                .book-info-container {
                    text-align: left;
                }
                
                .book-info-container p {
                    margin-top: 0;
                }

                .book-info-container:after {
                    float: unset;
                }
            </style>
            <div class="book-info-container">
                <a href="https://amzn.to/3Uopubu" target="_blank">
                    <img-smart src="./mm-cover.png" class="mm-cover"></img-smart>
                </a>
                <p>
                    If you enjoy Hexakai, consider trying out Brandon's other puzzles in his book, Mental Mosaics.
                </p>
                <p>
                    <a href="https://amzn.to/3Uopubu" target="_blank">Mental Mosaics</a> is a collection of 200 unique
                    puzzles and challenges on an eclectic range of subjects, including clocks and calendars, liars, demons,
                    chess boards, rotatable words, and hidden infinities. Each chapter is a self-contained unit that focuses
                    on its own unique subject, starts with one or two simple puzzles to set the tone, progresses to a number
                    of moderately difficult puzzles, and concludes with a small number of highly difficult puzzles to test
                    your mastery. This book includes 184 novel puzzles created by the author, Brandon Quinn, and ends with
                    16 puzzles that are famous across the world, concluding with <i>The Hardest Logic Puzzle Ever</i>.
                </p>
            </div>
        `
    },
    {
        displayMessage: html`We've launched our <a href="/blog">blog</a>! Take a look.`,
        modalTitle: `Hexakai's Blog`,
        modalButtons: [
            html`
                <styled-button @click="${() => {
                    const url = clientService.createLocalLink("/blog", {}, true);
                    window.location.href = url;
                }}">Open Blog</styled-button>
            `,
            html`
                <styled-button @click="${() => {
                    const latest = BLOG_DOCUMENTS[0];
                    const url = clientService.createLocalLink(`blog/${latest.path}`, {}, true);
                    window.location.href = url;
                }}">Read the Latest Blog Entry</styled-button>
            `
        ],
        modalTemplate: html`
            <p>
                We've launched a blog, where we'll post about new game features and share exciting updates about the
                Hexakai app, game as a whole, and upcoming releases!
            </p>
        `
    },
    {
        displayMessage: html`Hexakai also comes in <a href="https://amzn.to/4eW50QR" target="_blank">book format</a>!`,
        modalTitle: `Hexakai's Book`,
        modalButtons: [
            html`
                <styled-button @click="${() => window.open('https://amzn.to/3Nic8KV', '_blank')}">Get the Book</styled-button>
            `
        ],
        modalTemplate: html`
            <style>
                .mm-cover {
                    width: 160px;
                    aspect-ratio: 0.66;
                    float: left;

                    margin-right: 10px;
                    margin-bottom: 10px;
                }

                .book-info-container {
                    text-align: left;
                }
                
                .book-info-container p {
                    margin-top: 0;
                }

                .book-info-container:after {
                    float: unset;
                }
            </style>
            <div class="book-info-container">
                <a href="https://amzn.to/4eW50QR" target="_blank">
                    <img-smart src="./hexakai-book-cover.png" class="mm-cover"></img-smart>
                </a>
                <p>
                    <a href="https://amzn.to/3W44mYS" target="_blank">Hexakai</a> also comes in book format!
                </p>
                <p>
                    Inside, you'll find <b>150 unique puzzles</b>, including 10 bonus puzzles that are bigger and more difficult.
                    The book also has a chapter on strategies you can use to conquer boards, ranging from simple strategies to help get you into the game,
                    to complex strategies that will enable you to solve even ultra-difficult boards. There is also a chapter that
                    delves into the mathematical beauties and complexities of Hexakai.
                    Give it a shot, and see if you enjoy Hexakai in this paper-and-pencil format!
                </p>
            </div>
        `
    },

];

// TODO: add type here and above
export const INFO_BOX_DIFFICULTY_SIZE_MESSAGE = (
    difficulty: HexakaiGameDifficulty, 
    gameSize: number,
    cellType: CellValueType
): any => {
    const difficultyMessage = DIFFICULTY_TEXTS[difficulty];

    const possibleValues = HexakaiCellValueMapper.getPossibleValues(gameSize, cellType);
    const gameSizeMessage = html`
        On a board of size ${gameSize}, there are ${gameSize} diagonals in each direction, and the center row is ${gameSize} cells wide,
        for a total of ${gameSize*gameSize} cells on the board. Each cell has a range of ${gameSize} values it can be assigned.
        On this board size, the possible values are: ${possibleValues.join(", ")}.
    `;

    return {
        displayMessage: html`This puzzle is in ${difficulty} mode on a board of size ${gameSize}.`,
        modalTitle: `Your Game`,
        modalButtons: [],
        modalTemplate: html`
            <div style="text-align: left">
                <p>The puzzle you're playing is in ${difficulty} mode on a board of size ${gameSize}.</p>
                <p>${difficultyMessage}</p>
                <p>${gameSizeMessage}
            </div>
        `
    }
}