export class SoundPlayback {
    private isPlaying: boolean = false;
    private startPromise: Promise<void>;

    constructor(
        private audio: HTMLAudioElement
    ) {
        // Start audio playback immediately
        this.startPromise = new Promise<void>((resolve, reject) => {
            // Handle errors
            const onError = (e: Event) => {
                clearInterval(checkInterval);
                this.audio.removeEventListener('error', onError);
                reject(new Error('Failed to play audio'));
            };

            // Attach the error listener
            this.audio.addEventListener('error', onError);

            // Attempt to play the audio
            const playPromise = this.audio.play();
            if (playPromise !== undefined) {
                playPromise.catch((e) => {
                    clearInterval(checkInterval);
                    this.audio.removeEventListener('error', onError);
                    reject(e);
                });
            }

            // Polling mechanism to check when currentTime advances
            const checkInterval = setInterval(() => {
                if (this.audio.currentTime > 0 && !this.audio.paused) {
                    clearInterval(checkInterval);
                    this.audio.removeEventListener('error', onError);
                    this.isPlaying = true;
                    resolve();
                }
            }, 10); // Check every 10 milliseconds
        });
    }

    resolveOnStart(): Promise<void> {
        return this.startPromise;
    }

    resolveOnEnd(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // If the audio has already ended, resolve immediately
            if (this.audio.ended) {
                resolve();
                return;
            }

            const onEnded = () => {
                this.isPlaying = false;
                resolve();
                cleanup();
            };

            const onError = (e: Event) => {
                reject(new Error('Audio playback failed'));
                cleanup();
            };

            const cleanup = () => {
                this.audio.removeEventListener('ended', onEnded);
                this.audio.removeEventListener('error', onError);
            };

            // Remove any previous listeners
            this.audio.removeEventListener('ended', onEnded);
            this.audio.removeEventListener('error', onError);

            // Attach the event listeners
            this.audio.addEventListener('ended', onEnded);
            this.audio.addEventListener('error', onError);
        });
    }

    stop(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.isPlaying) {
                resolve(); // Resolve immediately if not playing
                return;
            }

            const onPause = () => {
                this.isPlaying = false;
                resolve();
                cleanup();
            };

            const onError = (e: Event) => {
                reject(new Error('Failed to stop audio'));
                cleanup();
            };

            const cleanup = () => {
                this.audio.removeEventListener('pause', onPause);
                this.audio.removeEventListener('error', onError);
            };

            // Remove any previous listeners
            this.audio.removeEventListener('pause', onPause);
            this.audio.removeEventListener('error', onError);

            // Attach the event listeners
            this.audio.addEventListener('pause', onPause);
            this.audio.addEventListener('error', onError);

            // Pause and reset the audio
            this.audio.pause();
            this.audio.currentTime = 0;
        });
    }
}
