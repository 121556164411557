import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { ClientService } from "../client-service/client-service";
import { tutorialStartedEvent } from "../user-events/events/tutorial-started-event";
import { tutorialFinishedEvent } from "../user-events/events/tutorial-finished-event";
import { tutorialIgnoredEvent } from "../user-events/events/tutorial-ignored-event";

export enum TutorialAction {
    continue = "continue",
    back = "back",
    exit = "exit",
    complete = "complete",
    callback = "callback"
}

export interface TutorialStep {
    selectComponent?: () => any,
    title: string | any; // any in case of html directive
    text: string | any;
    buttons?: {
        text: string;
        action: TutorialAction;
        callback?: Function;
    }[];
}

@customElement('hexakai-tutorial')
export class TutorialViewComponent extends LitElement {

    private static isConnected = false;

    private storageService = new LocalStorageService();
    private userEventsService = new UserEventsServiceComposite(
        new ClientService(),
        new LocalStorageService()
    );

    static styles = css`
        .tutorial-background {
            position: absolute;
            background: var(--underlay-color);
            opacity: 0.7;
        }

        #tutorial-container {
            position: absolute;
            font-size: 110%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            left: 50dvw;
            top: 50dvh;
            transform: translate(-50%, -50%);
            border: 1px solid var(--body-color);
            background-color: var(--background-color);

            border-radius: 20px;

            max-width: 90dvw;
            max-height: 70dvh;

            overflow: auto;

            z-index: 1000;
        }

        #tutorial {
            margin: 1.5em;
            overflow: auto;
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 1.2em;
            cursor: pointer;
            color: var(--body-color);
            border: 1px solid var(--body-color);
            border-radius: 10px;
        }

        .close-button:hover {
            background-color: var(--button-hover-background-color) !important;
        }

        @media screen and (max-width: 1200px) {
            #tutorial-container {
                width: 90dvw;
            }
        }

        @media screen and (max-width: 900px) {
            #tutorial-container {
                font-size: initial;
            }
        }
    `;

    private steps: TutorialStep[] = [
        {
            title: "Welcome to Hexakai!",
            text: `
                Would you like to take the tutorial?
            `,
            buttons: [{
                text: "No",
                action: TutorialAction.exit
            }, {
                text: "Yes",
                action: TutorialAction.continue,
            }]
        },
        {
            title: "How The Tutorial Works",
            text: `
                Let's walk through Hexakai's features and how to use them.
                This tutorial will highlight elements on the screen and provide instructions on how they work.
            `
        },
        {
            //selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector(".game-board"),
            title: "Hexakai's Board",
            text: html`
                <style>
                    #tut-style {
                        text-align: left;
                    }
                </style>
                <div id="tut-style">
                    <p>
                        The main board sits at the center of the screen.
                        You can interact with cells to assign values, apply pencil markings, and set colors.
                    </p>

                    <p>
                        <b>To assign a value</b>, left click or tap the cell, then click the value you want to assign in the screen
                        that appears.
                    </p>

                    <p>
                        <b>To assign a color or pencil mark</b>, right click or tap and hold the cell, then click the colors
                        and pencil markings that appear.
                    </p>
                </div>
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#zoom-container"),
            title: "Zooming In and Out",
            text: `
                Click the magnifying glass icons to zoom in and out.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#undo-redo-container"),
            title: "Undoing and Redoing",
            text: `
                Click these icons to undo cell assignments, color assignments, and pencil markings. Click the redo icon to re-apply them.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#new-game-button"),
            title: "Starting New Games",
            text: `
                You can start a new game by clicking the New Game button. This will let you set the game size, difficulty, and other options.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#hint-button"),
            title: "Taking Hints",
            text: `
                View hints by clicking the hint button. Each time you view a hint, you'll need to wait a small period of time before viewing another.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#reset-button"),
            title: "Starting Over",
            text: `
                If you feel stuck or think you made a mistake you can't recover from, hit the Reset button to wipe the slate clean.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#submit-button"),
            title: "Completing Games",
            text: `
                When you think you've beat the game, hit the Submit button! If you've won, the cells will flash green and you'll see a 
                reward popup. If you've made a mistake, the cells will flash red.
            `
        },
        {
            selectComponent: () => document.querySelector("#primary-game")?.shadowRoot?.querySelector("#info-box"),
            title: "Info Box",
            text: `
                The info box has tons of useful information. You can click on it to show the next message or click its info icon to pull up a modal box with more info.
            `
        },
        {
            selectComponent: () => document.getElementById("settings-container"),
            title: "Settings and Streaks",
            text: html`
                <p>
                    You can change your gameplay experience in the settings section of your account overview. Settings include
                    the app's color theme, the way it displays hex cell values, the way you can input menu options, and more.
                </p>
                <p>
                    You can also view your achievements and streaks on the account overview page.
                </p>
            `
        },
        {
            selectComponent: () => document.getElementById("rules-menu"),
            title: "Rules of the Game",
            text: html`
                <style>
                    #tutorial-rules p {
                        text-align: left;
                    }
                </style>
                <hexakai-game-rules id="tutorial-rules"></hexakai-game-rules>
            `
        },
        {
            title: "Board Sizes and Difficulties",
            text: html`
                <p>
                    Hexakai can be played on a variety of board sizes and difficulty levels. The classic size-10 board provides the
                    traditional experience. Smaller boards are easier to work with for new players and provide a more casual experience.
                    Larger boards increase the complexity of gameplay and the time it takes to solve them.
                </p>

                <p>
                    By default, we start new players on a board of size 7, but you can always change the size and difficulty by generating a new board
                    via the <i>New Game</i> button.
                </p>
            `
        },
        {
            selectComponent: () => document.getElementById("daily-menu"),
            title: "Daily Puzzles",
            text: `
                View and play the daily puzzles from the Daily menu above. A new puzzle is published everyday,
                and some include video playthroughs.
            `
        },
        {
            selectComponent: () => document.getElementById("menu-dropdown-select"),
            title: "Tutorial Complete",
            text: html`
                <p>
                    That's the end of the tutorial. If you have any questions,
                    feedback, bug reports, or feature requests, please don't
                    hesitate to contact us via the dropdown menu above.
                </p>  

                <p>
                    You can always re-watch the tutorial by selecting it in the dropdown menu.
                </p>
            `,
            buttons: [
                {
                    text: "Prev",
                    action: TutorialAction.back,
                },
                {
                    text: "Close",
                    action: TutorialAction.complete
                }]
        }
    ];

    private currentStep = 0;
    private prevOverflow!: string;

    connectedCallback(): void {
        super.connectedCallback();

        if (TutorialViewComponent.isConnected) {
            this.parentNode?.removeChild(this);
            return;
        }

        TutorialViewComponent.isConnected = true;

        window.addEventListener("resize", () => {
            this.requestUpdate();
        });
        this.storageService.set("tutorial-in-progress", {
            is: true
        });

        this.prevOverflow = document.body.style.getPropertyValue("overflow");
        document.body.style.setProperty("overflow", "hidden");
        this.userEventsService.logEvent(
            tutorialStartedEvent(void 0)
        );
    }

    createRenderRoot() {
        return this;
    }

    render() {
        if (this.currentStep === -1) {
            return;
        }

        const currentStep = this.steps[this.currentStep];

        const buttons: any[] = [];
        if (currentStep.buttons) {
            for (const button of currentStep.buttons) {
                let clickCallback: Function;
                switch (button.action) {
                    case TutorialAction.continue:
                        clickCallback = this.goForward.bind(this);
                        break;
                    case TutorialAction.back:
                        clickCallback = this.goBack.bind(this);
                        break;
                    case TutorialAction.exit:
                        clickCallback = this.exit.bind(this, false);
                        break;
                    case TutorialAction.complete:
                        clickCallback = this.exit.bind(this, true);
                        break;
                    case TutorialAction.callback:
                        clickCallback = button.callback!;
                        break;
                }

                buttons.push(html`
                    <styled-button @click="${() => clickCallback()}">${button.text}</html>
                `);
            }
        } else {
            if (this.currentStep > 0) {
                buttons.push(html`
                    <styled-button @click="${() => this.goBack()}">Prev</html>
                `);
            }
            
            if (this.currentStep < this.steps.length - 1) {
                buttons.push(html`
                    <styled-button @click="${() => this.goForward()}">Next</html>
                `);
            }
        }

        const textTemplate = typeof currentStep.text === "string"
            ? html`<p>${currentStep.text}</p>`
            : currentStep.text;

        // highlight element
        const elementToHighlight = currentStep.selectComponent?.();

        // handle background
        const backgrounds: any[] = [];
        if (elementToHighlight) {
            const { left, right, top, bottom } = elementToHighlight.getBoundingClientRect();

            // make eight boxes to surround the element
            // first four cover its corner boundaries
            backgrounds.push({
                left: 0,
                top: 0,
                width: `${left+3}px`,
                height: `${top-3}px`
            });
            backgrounds.push({
                left: 0,
                top: `${bottom-3}px`,
                width: `${left-3}px`,
                height: `100dvh`
            });
            backgrounds.push({
                left: `${right-3}px`,
                top: 0,
                width: `100dvw`,
                height: `${top-3}px`
            });
            backgrounds.push({
                left: `${right-3}px`,
                top: `${bottom+3}px`,
                width: `100dvw`,
                height: `100dvh`
            });
            // last four cover areas with borders
            backgrounds.push({
                left: 0,
                top: `${top-3}px`,
                width: `${left-3}px`,
                height: `${bottom-top}px`,
                border: "border-right: 6px solid var(--body-color)"
            });
            backgrounds.push({
                left: `${left+3}px`,
                top: 0,
                width: `${right-left-6}px`,
                height: `${top-3}px`,
                border: "border-bottom: 6px solid var(--body-color)"
            });
            backgrounds.push({
                left: `${right-3}px`,
                top: `${top-3}px`,
                width: `100dvw`,
                height: `${bottom-top+6}px`,
                border: "border-left: 6px solid var(--body-color)"
            });
            backgrounds.push({
                left: `${left-3}px`,
                top: `${bottom-3}px`,
                width: `${right-left}px`,
                height: `100dvh`,
                border: "border-top: 6px solid var(--body-color)"
            });
        } else {
            backgrounds.push({
                left: 0,
                top: 0,
                width: `100dvw`,
                height: `100dvh`
            });
        }

        return html`
            <style>
                ${TutorialViewComponent.styles}
            </style>
            ${backgrounds.map(b => html`
                <div class="tutorial-background"
                    style="${b.border ? b.border+"; " : ""}left: ${b.left}; top: ${b.top}; width: ${b.width}; height: ${b.height};">
                </div>
            `)}
            <div id="tutorial-container">
                <div id="tutorial">
                    <button class="close-button" @click="${() => this.exit(this.currentStep === this.steps.length - 1)}">&#10005;</button>
                    <h1>${currentStep.title}</h1>
                    ${textTemplate}
                    <div class="tutorial-buttons">
                        ${buttons}
                    </div>
                </div>
            </div>
        `
    }

    private goForward(): void {
        this.currentStep++;
        this.requestUpdate();
    }

    private goBack(): void {
        this.currentStep--;
        this.requestUpdate();
    }

    private exit(isFinished: boolean): void {
        this.currentStep = -1;
        this.storageService.set("tutorial-in-progress", {
            is: false
        });
        if (isFinished) {
            this.userEventsService.logEvent(
                tutorialFinishedEvent(void 0)
            );
        } else {
            this.userEventsService.logEvent(
                tutorialIgnoredEvent(void 0)
            );
        }
        this.parentNode?.removeChild(this);
        TutorialViewComponent.isConnected = false;
        document.body.style.setProperty("overflow", this.prevOverflow);
        this.requestUpdate();
    }
}