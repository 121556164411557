import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { ModalLayover } from './modal-layover';
import { ClientService } from '../client-service/client-service';
import { ModalService } from '../modal-service/modal-service';

@customElement('hexakai-welcome-modal')
class WelcomeModal extends LitElement {

    private clientService = new ClientService();
    private modalService = new ModalService();

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.showWelcomeModal();
    }

    private showWelcomeModal(): void {
        const modalId = "welcome-hexakai-modal";
        const modalTitle = "Welcome to Hexakai!";

        //modal.classList.add('left-content')

        const openRules = () => {
            const url = this.clientService.createLocalLink("rules", {}, true);
            window.location.href = url;
        };

        const openBook = () => {
            window.open('https://amzn.to/3zF9KKG', '_blank');
        };

        const openAppModal = () => {
            this.modalService.hideModal(modalId);
            this.showAppModal();
        }

        const getTheApp = this.clientService.getConfig().gameBoard.showAppMessage
            ? html`<styled-button @click="${() => openAppModal()}">Get the App</styled-button>`
            : ``;

        const getTheBook = getTheApp
            ? ''
            : html`<styled-button @click="${() => openBook()}">Get the Book</styled-button>`;

        const modalContents = html`
            <style>
                #buttons {
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    flex-wrap: wrap;
                }

                #buttons styled-button {
                    margin: 10px;                   
                }
            </style>
            <hexakai-game-intro></hexakai-game-intro>
            <p>
                If you're new to the game, take a look at the rules. If you enjoy this game,
                consider getting the book!
            </p>
            <div id="buttons">
                <styled-button @click="${() => openRules()}">Read the Rules</styled-button>
                ${getTheBook}
                ${getTheApp}
                <styled-button id="welcome-play-now" @click="${() => this.modalService.hideModal(modalId)}">Play Now!</styled-button>
            </div>
        `;

        this.modalService.showModal(
            modalId,
            modalTitle,
            modalContents,
            {
                maxWidth: '750px'
            }
        );
    }

    private showAppModal(): void {
        const modal = new ModalLayover();
        modal.title = "Get the App";

        const openAndroid = () => {
            window.open('https://play.google.com/store/apps/details?id=com.hexakai.andrioid.app', '_blank');
        };

        const openApple = () => {
            window.open('https://apps.apple.com/us/app/hexakai/id6504585426', '_blank');
        };

        modal.contentTemplate = html`
            <style>
                #buttons {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }

                #buttons styled-button {
                    margin: 10px;
                }
            </style>
            <p>
                For which platform would you like to get the app?
            </p>
            <div id="buttons">
                <styled-button @click="${() => openAndroid()}">Android</styled-button>
                <styled-button @click="${() => openApple()}">Apple</styled-button>
            </div>
        `;
        modal.open = true;

        document.body.appendChild(modal);
    }
}

