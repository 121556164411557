import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('hexakai-game-intro')
class AppRules extends LitElement {
    static styles = css`
        
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        return html`
        <p>
            Hexakai, inspired by Sudoku, is played on a hexagonal diamond-shaped grid of cells.
            Usually, Hexakai games are of size 10, which means the middle row has 10 cells and the
            board has a total of 100 cells.
        </p>
    `;
    }
}

