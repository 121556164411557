import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('loading-display')
class LoadingDisplay extends LitElement {
    
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--body-color); // Dark Gray
            background-color: var(--button-background-color); // Pale Blue
            padding: 10px;
        }

        .dots {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .dot {
            width: 1em;
            height: 1em;
            margin: 0.5em;
            background-color: var(--body-color);
            border-radius: 50%;
            animation: dotFluctuate 1.5s infinite ease-in-out both;
        }

        .dot:nth-child(1) { animation-delay: -0.5s; }
        .dot:nth-child(2) { animation-delay: -0.25s; }
        .dot:nth-child(3) { animation-delay: 0; }

        @keyframes dotFluctuate {
            0%, 80%, 100% {
                transform: scale(0);
            }
            40% {
                transform: scale(1);
            }
        }
    `;

    render() {
        return html`
            <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <br/>
            <slot></slot> <!-- Slot for custom content -->
        `;
    }
}
