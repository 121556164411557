/**
 * Encapsulates a cell constraint, prepopulated with all possible values in a randomized order
 */
export class HexakaiCellConstraint {

    // maintain choices as a list and set for easy access and updates
    // randomize order to facilitate board generation
    private choicesList: string[];
    private choices: Map<string, number>;
    private numChoices: number;
    private cellValue: string | null = null;

    private permanenetAssignment: string | null = null;

    constructor(
        choicesList: string[],
        private row: number,
        private col: number
    ) {
        this.choicesList = choicesList;
        this.choices = new Map<string, number>(
            this.choicesList.map((value) => [value, 1])
        );

        this.numChoices = this.choices.size;
    }

    /**
     * Set an assignment that can never be undone
     * Useful for attempting to solve a game and showing only one solution exists
     */
    public setPermanentAssignment(value: string): void {
        this.permanenetAssignment = value;
        this.cellValue = value;
        this.choicesList = [value];
        this.choices.clear();
        this.choices.set(value, 1);
        this.numChoices = 1;
    }

    public isPermanentlyAssigned(): boolean {
        return this.permanenetAssignment !== null;
    }

    public getRow(): number {
        return this.row;
    }

    public getCol(): number {
        return this.col;
    }

    /**
     * Get the value of this cell
     */
    public getValue(): string {
        if (this.cellValue === null) {
            throw new Error("This cell doesn't have a value assigned.");
        }
        return this.cellValue;
    }

    /**
     * Try to get the value, return "." if none found
     */
    public tryGetValue(): string {
        return this.cellValue || ".";
    }

    /**
     * Set the value of this cell, or throw new Error(error if the choice isn't available
     */
    public setValue(value: string): void {
        if (this.isPermanentlyAssigned()) {
            return;
        }

        if (this.cellValue !== null) {
            throw new Error("The cell value is already set!");
        }
        if (!this.hasChoice(value)) {
            throw new Error("The cell value isn't a valid choice: " + value);
        }

        this.cellValue = value;
    }

    public hasValueSet(): boolean {
        return this.cellValue !== null;
    }

    /**
     * Iterate over the choices in this set
     */
    public *getChoices(): Iterable<string> {
        for (const choice of this.choicesList) {
            if (this.hasChoice(choice)) {
                yield choice;
            }
        }
    }

    /**
     * Check how many choices are remaining
     */
    public getNumChoices(): number {
        return this.numChoices;
    }

    /**
     * Unset the value of this cell, restoring the available choices
     */
    public unsetValue(): void {
        if (this.isPermanentlyAssigned()) {
            return;
            //throw new Error("Can't unset a permanently assigned value!");
        }
        
        this.cellValue = null;
    }

    /**
     * Mark that this cell cannot be assigned a certain value
     * Useful for constraint propagation
     */
    public removeChoice(value: string): void {
        if (this.permanenetAssignment !== null) {
            return;
        }

        this.choices.set(
            value,
            this.choices.get(value)! - 1
        );

        if (this.choices.get(value)! === 0) {
            this.numChoices --;
        }
    }

    /**
     * Mark that this cell can be assigned a certain value
     * Useful for constraint propagation
     */
    public addChoice(value: string): void {
        if (this.permanenetAssignment !== null) {
            return;
        }

        if (this.hasChoice(value)) {
            throw new Error("This cell already has " + value + " as a choice.");
        }
        this.choices.set(
            value,
            this.choices.get(value)! + 1
        );

        if (this.hasChoice(value)) {
            this.numChoices ++;
        }
    }

    /**
     * Check if this has a certain choice
     */
    public hasChoice(value: string): boolean {
        return this.choices.get(value) === 1;
    }

    public toString(): string {
        return `value:${this.tryGetValue()},row:${this.getRow()},col:${this.getCol()}`;
    }
}