export enum HexakaiGameHintType {
    wrongValue = "wrong value",
    valueHint = "value hint"
}

export interface HexakaiGameHint {

    row: number;
    col: number;
    hintType: HexakaiGameHintType;
    valueRange: string[]
}