import { RngParams } from "../models/rng-params";
import { ShufflerParams } from "../models/shuffler-params";
import { getRandom } from "./get-random";
import { ShufflerFactory } from "./shuffler-factory";

const shufflerFactory = new ShufflerFactory();

/**
 * Randomly shuffles an array using the Fisher-Yates algorithm.
 */
export function shuffleInPlace<T>(
    array: T[],
    seed?: number | string | RngParams | ShufflerParams
): void {
    if (isShufflerParams(seed)) {
        shufflerFactory.get<T>(seed).shuffleInPlace(array);
        return;
    }

    // otherwise
    const random = getRandom.bind(getRandom, seed);

    for (let i = array.length - 1; i > 0; i--) {
        // Generate a random index before the current element
        let j = Math.floor(random() * (i + 1));
        // Swap the current element with the randomly chosen element
        [array[i], array[j]] = [array[j], array[i]];
    }
}

/**
 * Shuffle an array to a new array
 */
export function shuffleToNew<T>(
    array: T[],
    seed?: number | string | RngParams | ShufflerParams
): T[] {
    if (isShufflerParams(seed)) {
        return shufflerFactory.get<T>(seed).shuffleToNew(array);
    }

    // Copy the original array to avoid mutating it
    const copiedArray = array.slice();
    shuffleInPlace(copiedArray, seed);
    return copiedArray;
}

function isShufflerParams(
    params?: number | string | RngParams | ShufflerParams
): params is ShufflerParams {
    if (!params) {
        return false;
    }

    return typeof (params as ShufflerParams).shufflerType !== 'undefined';
}