import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ClientService } from '../client-service/client-service';
import { BLOG_DOCUMENTS } from '../scripts/blog-paths';


@customElement('blog-view')
export class BlogView extends LitElement {

    private NUM_BLOG_POSTS_IN_VIEW = 4;
    
    private isEnd = false;
    private isStart = false;

    private blogPosts = BLOG_DOCUMENTS;
    private numBlogPosts = this.blogPosts.length;
    private currentBlogPost = 0;

    private clientService = new ClientService();

    static styles = css`
        .blog-info-container {
            border: 1px solid var(--body-color);
            border-radius: 10px;
            padding: 1em;
            cursor: pointer;
            margin-bottom: 1em;
        }

        .blog-info-container:hover {
            background-color: var(--input-border-color);
        }

        .blog-info {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding-left: 5%;
            padding-right: 5%;
            padding-top: 1em;
            padding-bottom: 1em;
        }

        .blog-img {
            align-self: center;
            --width: auto;
            --max-width: 100%;
            --max-height: 18em;
        }

        .blog-title {
            text-align: center;
            font-size: 1.3em;
        }
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    render() {
        this.isEnd = this.currentBlogPost - this.NUM_BLOG_POSTS_IN_VIEW < 0;
        this.isStart = this.currentBlogPost + this.NUM_BLOG_POSTS_IN_VIEW >= this.numBlogPosts;

        const postsInView = this.blogPosts.filter((_, ind) => {
            return ind >= this.currentBlogPost && ind < this.currentBlogPost + this.NUM_BLOG_POSTS_IN_VIEW
        });

        console.debug(`[BlogView] showing posts`, {
            postsInView,
            currentBlogPost: this.currentBlogPost,
            isStart: this.isStart,
            isEnd: this.isEnd,
            allPosts: this.blogPosts
        });

        return html`
            <style>
                ${BlogView.styles}
            </style>
            ${postsInView.map((post) => html`
                <div class="blog-info-container" @click="${() => this.openPost(post.path)}">
                    <div class="blog-info">
                        ${post.metadata.meta_img ? html`<img-smart class="blog-img" src="${post.metadata.meta_img}"></img-smart>` : ''}
                        <h3 class="blog-title">${post.metadata.meta_title}</h3>
                        <div>
                            <p><b>Date: </b>${new Date(`${post.metadata.date_created}T00:00:00`).toLocaleDateString()}
                            <p><b>Description: </b>${post.metadata.meta_description}</p>
                        </div>
                    </div>
                </div>
            `)}
            <div id="puzzle-paginate">
                <styled-button style="${this.isStart ? 'display: none;' : ''}" @click="${() => this.goPrev()}">Previous</styled-button>
                <styled-button style="${this.isEnd ? 'display: none;' : ''}" @click="${() => this.goNext()}">Next</styled-button>
            </div>
        `;
    }

    private goNext(): void {
        // if at the top, stop
        if (this.currentBlogPost - this.NUM_BLOG_POSTS_IN_VIEW < 0) {
            return;
        }

        // update the positions and re-load data
        this.currentBlogPost -= this.NUM_BLOG_POSTS_IN_VIEW;

        this.requestUpdate();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.clientService.notifyInnerNavigationOccurred();
    }

    private goPrev(): void {
        // if at the top, stop
        if (this.currentBlogPost + this.NUM_BLOG_POSTS_IN_VIEW >= this.numBlogPosts) {
            return;
        }

        // update the positions and re-load data
        this.currentBlogPost += this.NUM_BLOG_POSTS_IN_VIEW;

        this.requestUpdate();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.clientService.notifyInnerNavigationOccurred();
    }

    private async openPost(path: string): Promise<void> {
        console.log("[BlogView] path opening", path);
        const updatedUrl = this.clientService.createLocalLink(
            "blog/" + path,
            {},
            true
        );
        console.log("[BlogView] navigating", updatedUrl)
        window.location.href = updatedUrl;
    }
}