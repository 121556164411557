import { ShufflerParams } from "../models/shuffler-params";

export abstract class BaseShuffler<T> {

    constructor(
        private params: ShufflerParams
    ) {}

    shuffleInPlace(input: T[]): void {
        this.doShuffle(input);
    }

    /**
     * Shuffles a shallow copy, then returns that
     */
    shuffleToNew(input: T[]): T[] {
        const newInput = [...input];
        this.shuffleInPlace(newInput);
        return newInput;
    }

    protected abstract doShuffle(input: T[]): void;

}