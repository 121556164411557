import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class LogisticMapRng extends BaseRng {

    private lastValue = 0;
    private r = 3.8;
    
    constructor(params: RngParams) {
        super(params);
        if (params.seed) {
            this.lastValue = typeof params.seed === 'string'
                ? this.stringToNumber(params.seed)
                : params.seed;
        } else {
            this.lastValue = Math.random();
        }

        if (params.data?.r) {
            this.r = params.data.r;
        }
    }

    protected getNext(): number {
        return this.r * this.lastValue * (1 - this.lastValue);
    }
   
}