import { ClientService } from "../../client-service/client-service";
import { HexakaiBoardData } from "../../hexakai-board/hexakai-board-data";
import { HexakaiGameDifficulties, HexakaiGameDifficulty } from "../../models/hexakai-game-params";
import { RngParams, RngType } from "../../models/rng-params";
import { ShufflerParams, ShufflerType } from "../../models/shuffler-params";

// [...document.querySelector("#primary-game").shadowRoot.querySelectorAll("hex-cell")].flatMap(hex => [...hex.shadowRoot.querySelectorAll("p")]).forEach(p => p.innerText = "")

const clientService = new ClientService();

export interface GeneratorPatternConfig {
    generatorId: string,
    name: string;
    description: string;
    img: string;
    allowedContexts: {
        gameSize: number;
        difficulty: HexakaiGameDifficulty
    }[];
    valueRandomSeed: number | string | RngParams,
    hintRandomSeed: number | string | RngParams | ShufflerParams,
}

const allSizesDifficulties = HexakaiBoardData.GAME_SIZES.flatMap(
    gameSize => HexakaiGameDifficulties.map(difficulty => ({
        gameSize,
        difficulty
    }))
);

const diffsInSize = (gameSize: number, diffs: HexakaiGameDifficulty[]) => {
    return diffs.map(difficulty => ({
        gameSize,
        difficulty
    }))
}

export const GENERATOR_STANDARD: GeneratorPatternConfig = {
    generatorId: "standard",
    name: "Standard",
    description: "Generate a board using the standard algorithm.",
    img: "/generator-standard.png",
    allowedContexts: allSizesDifficulties,
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <RngParams>{
        rngtype: RngType.default
    }
};

export const GENERATOR_TWO_LINE_CLUSTERS: GeneratorPatternConfig = {
    generatorId: 'two-line-clusters',
    name: "Two-Line Clusters",
    description: "Generate a board with two line-clusters surrounded by empty cells.",
    img: "/generator-line-clusters-two.png",
    allowedContexts: [
        ...diffsInSize(5, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult])
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.listCluster,
        data: {
            numClusters: 2
        }
    }
};

export const GENERATOR_THREE_LINE_CLUSTERS: GeneratorPatternConfig = {
    generatorId: 'three-line-clusters',
    name: "Three-Line Clusters",
    description: "Generate a board with three line-clusters surrounded by empty cells.",
    img: "/generator-line-clusters.png",
    allowedContexts: [
        {
            gameSize: 5,
            difficulty: HexakaiGameDifficulty.difficult,
        },
        ...diffsInSize(7, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(11, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult])
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.listCluster,
        data: {
            numClusters: 3
        }
    }
};

export const GENERATOR_ROW_STRINGS: GeneratorPatternConfig = {
    generatorId: 'row-strings',
    name: "Row Strings",
    description: "Generate a board that favors alternating rows of strings.",
    img: "/generator-row-strings.png",
    allowedContexts: [
        ...diffsInSize(5, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(11, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult])
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.hexDiagonalAlternation,
        data: {
            skip: 1,
            type: 'row'
        }
    }
};

export const GENERATOR_DIAGONAL_STRINGS: GeneratorPatternConfig = {
    generatorId: 'diagonal-strings',
    name: "Diagonal Strings",
    description: "Generate a board that favors long, diagonal strings.",
    img: "/generator-diagonal-strings.png",
    allowedContexts: [
        ...diffsInSize(5, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(11, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult])
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.hexDiagonalAlternation,
        data: {
            skip: 1
        }
    }
};

export const GENERATOR_CHECKERS: GeneratorPatternConfig = {
    generatorId: 'hex-checkers',
    name: "Checkered Board",
    description: "Generate a board that resembles a checkers pattern.",
    img: "/generator-checkers.png",
    allowedContexts: [
        ...diffsInSize(3, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(5, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(11, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult])
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.hexChecker,
        data: {}
    }
}

export const GENERATOR_WINDING_STRINGS: GeneratorPatternConfig = {
    generatorId: 'winding-strings',
    name: "Winding Strands",
    description: "Generate a board that is more likely to contain long strands of cells.",
    img: "/generator-winding-strings.png",
    allowedContexts: [
        ...diffsInSize(3, [HexakaiGameDifficulty.easy, HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult]),
        ...diffsInSize(5, HexakaiGameDifficulties),
        ...diffsInSize(7, HexakaiGameDifficulties),
        ...diffsInSize(8, HexakaiGameDifficulties),
        ...diffsInSize(9, HexakaiGameDifficulties),
        ...diffsInSize(10, HexakaiGameDifficulties),
        ...diffsInSize(11, HexakaiGameDifficulties),
        ...diffsInSize(12, HexakaiGameDifficulties),
        ...diffsInSize(13, HexakaiGameDifficulties),
        ...diffsInSize(14, HexakaiGameDifficulties),
        ...diffsInSize(15, HexakaiGameDifficulties),
        ...diffsInSize(16, HexakaiGameDifficulties),
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <RngParams>{
        rngtype: RngType.rootRatio,
        data: {
            //root: Math.E, /*[Math.E, /*golden ratio*/ (1 + Math.sqrt(5)) / 2]*/
        }
    }
}

export const GENERATOR_GALTON_BOARD: GeneratorPatternConfig = {
    generatorId: 'galton-board',
    name: "Galton Board",
    description: "Generate a board that resembles beads falling through a Galton Board.",
    img: "/generator-galton-board.png",
    allowedContexts: [
        ...diffsInSize(5, [HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(9, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(10, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(11, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(12, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(13, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(16, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <RngParams>{
        rngtype: RngType.fractional,
        data: {
            r: 5
        }
    }
}

export const GENERATOR_EMPTY_CENTER: GeneratorPatternConfig = {
    generatorId: 'empty-center',
    name: "Hollow Center",
    description: "Generate a board where the cells are focused away from the center.",
    img: "/generator-empty-center.png",
    allowedContexts: allSizesDifficulties,
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.hexEmptyCenter,
        data: {}
    }
}

export const GENERATOR_TOP_HALF: GeneratorPatternConfig = {
    generatorId: 'filled-top-half',
    name: "Top Heavy",
    description: "Generate a board where the cells are clustered at the top.",
    img: "/generator-top-half.png",
    allowedContexts: [
        ...diffsInSize(3, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(5, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(7, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.ultraDifficult]),
        ...diffsInSize(8, HexakaiGameDifficulties),
        ...diffsInSize(9, HexakaiGameDifficulties),
        ...diffsInSize(10, HexakaiGameDifficulties),
        ...diffsInSize(11, HexakaiGameDifficulties),
        ...diffsInSize(12, HexakaiGameDifficulties),
        ...diffsInSize(13, HexakaiGameDifficulties),
        ...diffsInSize(14, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.easy]),
        ...diffsInSize(15, [HexakaiGameDifficulty.medium, HexakaiGameDifficulty.difficult, HexakaiGameDifficulty.easy]),
    ],
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <RngParams>{
        rngtype: RngType.rootRatio,
        data: {
            root: 2,
            isAdd: true
        }
    }
}

// for internal purposes, allow handle to experimental
export const GENERATOR_EXPERIMENTAL: GeneratorPatternConfig = {
    generatorId: '-experimental-generator',
    name: "Experimental",
    description: "Use the experimental generator for dev purposes.",
    img: "/generator-random.png",
    allowedContexts: allSizesDifficulties,
    valueRandomSeed: <RngParams>{
        rngtype: RngType.default
    },
    hintRandomSeed: <ShufflerParams>{
        shufflerType: ShufflerType.hexDiagonalAlternation,
        data: {
            skip: 1,
            type: 'row'
        }
    }
}

// @ts-ignore - we only need a partial data here
export const GENERATOR_RANDOM: GeneratorPatternConfig = {
    generatorId: 'random-generator',
    name: "Random",
    description: "Randomly pick one of the options above.",
    img: "/generator-random.png",
    allowedContexts: allSizesDifficulties
}

export const GENERATOR_PROFILES = [
    GENERATOR_STANDARD,
    GENERATOR_ROW_STRINGS,
    GENERATOR_DIAGONAL_STRINGS,
    GENERATOR_CHECKERS,
    GENERATOR_EMPTY_CENTER,
    GENERATOR_TWO_LINE_CLUSTERS,
    GENERATOR_THREE_LINE_CLUSTERS,
    GENERATOR_GALTON_BOARD,
    GENERATOR_WINDING_STRINGS,
    GENERATOR_TOP_HALF,
    clientService.getConfig().gameBoard.generatorPatternCreate.experimentalEnabled
        ? GENERATOR_EXPERIMENTAL
        : null,
    GENERATOR_RANDOM
].filter(item => !!item);

export const GENERATOR_PROFILE_LOOKUP = GENERATOR_PROFILES.reduce((obj, entry) => {
    obj[entry.generatorId] = entry;
    return obj;
}, {} as {[key: string]: GeneratorPatternConfig});

export const GENERATOR_PROFILE_DEFAULT = GENERATOR_RANDOM;