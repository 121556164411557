import { SoundPlayback } from "./sound-playback";
import { SOUND_PATHS, Sounds } from "./sounds";

export class SoundService {

    private static instance: SoundService;

    constructor() {
        if (SoundService.instance) {
            return SoundService.instance;
        }
    }

    play(soundPath: Sounds | string): SoundPlayback {
        if (soundPath.indexOf(".") === -1) {
            soundPath = SOUND_PATHS[soundPath as Sounds];
        }
        return new SoundPlayback(new Audio(soundPath));
    }
}