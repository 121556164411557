import { LitElement, html, css } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';

@customElement('non-navigator-share')
export class NonNavigatorShare extends LitElement {
    @property({ type: String }) title!: string;
    @property({ type: String }) text!: string;
    @property({ type: String }) url!: string;

    createRenderRoot() {
        return this;
    }

    render() {
        const encodedTitle = encodeURIComponent(this.title);
        const encodedContentWithNoLink = encodeURIComponent(this.text);
        const encodedContentWithLink = encodeURIComponent(`${this.text}
            
${this.url}`);
        const encodedUrl = encodeURIComponent(this.url);

        const redditUrl = `https://new.reddit.com/submit?type=text&title=${encodedTitle}&text=${encodedContentWithLink}`;
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&text=${encodedContentWithNoLink}`;
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedContentWithNoLink}`;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}&text=${encodedContentWithNoLink}`
        const emailUrl = `mailto:?subject=${encodedTitle}&body=${encodedContentWithLink}`;
        const pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${encodedUrl}&description=${encodedContentWithNoLink}`;

        const copyUrl = (event: Event) => {
            event.stopPropagation();
            console.log("[HexakaiBoard] copying url");
            navigator.clipboard.writeText(this.url);

            const target = this.querySelector(`input`)! as any;
            target.value = "Copied!";
            setTimeout(() => {
                target.value = this.url;
            }, 3_000);
        }

        return html`
            <style>
                #share-container {
                    margin-top: 10px;
                    display: flex;
                    gap: 2.5em;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                }
                .share-icon {
                    font-size: 3rem;
                    cursor: pointer;
                    color: var(--body-color);
                    transition: color 0.3s ease;
                    text-decoration: none;
                }
                .share-icon:hover {
                    color: var(--hover-color);
                }
                .clickable-div {
                    height: fit-content;
                    cursor: pointer;
                    position: relative;
                }

                .input-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent;
                }

                .input-field {
                    width: 95%;
                    pointer-events: none;
                    text-align: center;
                }

                @media screen and (max-width: 800px) {
                    #share-container {
                        gap: 1em;
                    }
                        
                    .share-icon {
                        font-size: 2rem;
                    }   
                }
            </style>
            <p>Share directly to a social media platform or copy & paste from the address bar below.</p>
            <div id="share-container">
                <a href="${facebookUrl}" target="_blank" class="fab fa-facebook-square share-icon"></a>
                <a href="${twitterUrl}" target="_blank" class="fab fa-twitter-square share-icon"></a>
                <a href="${pinterestUrl}" target="_blank" class="fab fa-pinterest share-icon"></a>
                <a href="${redditUrl}" target="_blank" class="fab fa-reddit-square share-icon"></a>
                <a href="${linkedinUrl}" target="_blank" class="fab fa-linkedin share-icon"></a>
                <a href="${emailUrl}" target="_blank" class="fas fa-envelope share-icon"></a>
            </div>
            <br/>
            <div class="clickable-div" @click="${(evt: Event) => copyUrl(evt)}">
                <input type="text" class="input-field" disabled value="${this.url}" />
                <div class="input-overlay"></div>
            </div>
        `;
    }
}
