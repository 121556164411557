import { RngParams } from "../../models/rng-params";
import { getRandom } from "../get-random";
import { BaseRng } from "../rng";

export class RootRatioRng extends BaseRng {

    private n = 1;
    private goldenRatio = (1 + Math.sqrt(5)) / 2;
    //private goldenRatio = Math.cbrt(3);
    private root = this.goldenRatio;
    private isAdd: boolean;
    private seed: any;

    constructor(params: RngParams) {
        super(params);

        if (typeof params.seed !== 'undefined') {
            this.seed = typeof params.seed === 'string'
                ? this.stringToNumber(params.seed)
                : params.seed;
        }

        if (params.data?.root) {
            this.root = params.data.root;
        }

        if (params.data?.isAdd) {
            this.isAdd = params.data.isAdd;
        } else {
            this.isAdd = getRandom(this.seed) < 0.5;
        }
    }

    
    protected getNext(): number {
        if (this.isAdd) {
            this.n = (this.n + this.root) % 1; // Wrap around between 0 and 1
        } else {
            this.n = this.n - this.root;
            if (this.n < 0) {
                this.n -= Math.floor(this.n);
            }
        }
    
        return this.n;
    }
}