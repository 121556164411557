import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('hexakai-app-rules')
class AppRules extends LitElement {
    static styles = css`
        
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        return html`
            <ul>
                <li>
                    <b>Daily puzzles:</b> enjoy a new puzzle every day. Each daily puzzle is uniquely created across a range of board sizes and difficulties.
                </li>
                <li>
                    <b>Solving the board:</b> click on empty hexagons and select the value you would like to assign to them.
                    When you're ready to submit your answer, click the submit button. If you've solved it, the board will
                    turn green. If not, the cells you've filled out will flash red. To restart, click the reset button.
                </li>
                <li>
                    <b>Navigating the board:</b> if the game is larger than your display, parts of it will remain hidden.
                    Drag the board around with your mouse or finger to focus on the part of the game you need to focus on.
                    Click the zoom buttons to zoom in or out.
                </li>
                <li>
                    <b>Taking notes:</b>
                    Use the game's built-in pencil-marking and color-coding features to keep track of cell possibilities and tentative assignments.
                    To use this feature, right click or long press a cell to apply pencil markings and colors.
                    Or, if you're using the unified menu (found under settings), all controls will appear on the same input panel.
                </li>
                <li>
                    <b>Creating your own game:</b> you can create your own games of various board sizes and difficulties. To
                    do so, click the new game button and select the kind of game you'd like. Note that larger games and
                    higher difficulties take longer to generate.
                </li>
                <li>
                    <b>Making mistakes:</b> use the game's undo and redo buttons in case you've made a move you'd like to take back. You can use it for setting values and setting colors, but not if you reset the game or start a new game.
                </li>
                <li>
                    <b>Saving your game for later:</b> your game is saved to your device automatically. The next time you visit, your game will be waiting for you just as you left it.
                </li>
                <li>
                    <b>Themes and settings:</b> the app comes with a handful of color themes. To change the theme or other settings, 
                        click the button at the top right of the page. You can also toggle sounds.
                </li>
                <li>
                    <b>Getting achievements:</b> get the recognition you deserve by winning achievements when you complete games!
                </li>
            </ul>
    `;
    }
}

