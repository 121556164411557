import { EventListenerHandle } from "./event-listener-handle";

export class EventListener {

    public static add(
        source: any,
        events: string[] | string,
        destructure: string[] | string,
        callback: (data: {[key: string]: any}, original: any) => void,
        once: boolean = false
    ): EventListenerHandle {
        if (typeof events === 'string') {
            events = [events];
        }

        if (typeof destructure === 'string') {
            destructure = [destructure];
        }

        const addEventListener = source.addEventListener
            ? source.addEventListener.bind(source)
            : source.on.bind(source)

        let unsubscribers: Function[] = [];

        for (const event of events) {
            const handle = addEventListener(event, (evt: any) => {
                const data: any = {};
                for (const item of destructure) {
                    data[item] = evt[item] || null;
                }

                callback(data, evt);

                if (once) {
                    if (source.removeEventListener) {
                        source.removeEventListener(event, handle)
                    } else if (handle.unsubscribe) {
                        handle.unsubscribe()
                    }
                }
            });

            if (source.removeEventListener) {
                unsubscribers.push(() => source.removeEventListener(event, handle));
            } else if (handle.unsubscribe) {
                unsubscribers.push(() => handle.unsubscribe());
            }
        }

        return new EventListenerHandle(unsubscribers);
    }
}