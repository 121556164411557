import { HexakaiGameHint, HexakaiGameHintType } from "../models/hexakai-game-hint";
import { HexakaiGameSession } from "../models/hexakai-game-session";
import { HexakaiGameSolver } from "./hexakai-game-solver";

/**
 * Service to find a hint given a partially filled board
 * Hints include telling the user when a cell is wrong or making a suggestion for a
 * cell value
 */
export class HexakaiHintFinder {

    constructor(
        private solver: HexakaiGameSolver
    ) {}

    async getHints(session: HexakaiGameSession): Promise<HexakaiGameHint[]> {
        const hints: HexakaiGameHint[] = [];

        // first, solve the board
        if (!session.solution) {
            this.solveBoard(session);
        }

        // find cells that are incorrect
        const cells = session.boardState!.cells;
        const solution = session.solution!.cells;
        // filter non-disabled-cells
        for (let row = 0; row < cells.length; row ++) {
            for (let col = 0; col < cells[row].length; col++) {
                if (cells[row][col]) {
                    if (cells[row][col] !== solution[row][col]) {
                        hints.push({
                            row,
                            col,
                            hintType: HexakaiGameHintType.wrongValue,
                            // for now, just set to correct value
                            valueRange: [solution[row][col]]
                        });
                    }
                } else {
                    hints.push({
                        row,
                        col,
                        hintType: HexakaiGameHintType.valueHint,
                        // for now, just set to correct value
                        valueRange: [solution[row][col]]
                    });
                }
            }
        }

        return hints;
    }

    private solveBoard(session: HexakaiGameSession): void {
        const cells = session.boardState!.cells;
        // filter non-disabled-cells
        for (let row = 0; row < cells.length; row ++) {
            for (let col = 0; col < cells[row].length; col++) {
                if (session.disabledCells![row].indexOf(col) === -1) {
                    cells[row][col] = '';
                }
            }
        }

        const solution = this.solver.solve({
            gameSize: session.params.gameSize,
            cells
        });

        session.solution = solution[0];
    }

}