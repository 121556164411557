import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";

export class CircleRng extends BaseRng {

    private readonly fullCircle: number = 2 * Math.PI; // Full circle in radians (360 degrees)   
    private angle = Math.random() * this.fullCircle;
    private stepSize = Math.PI / 8;

    constructor(
        params: RngParams
    ) {
        super(params);
        if (params.data?.stepSize) {
            this.stepSize = params.data.stepSize;
        }
        if (typeof params.seed !== 'undefined') {
            this.angle = typeof params.seed === 'string'
                ? this.stringToNumber(params.seed)
                : params.seed;
        }
    }

    getNext(): number {
        // Move along the circle
        this.angle = (this.angle + this.stepSize) % this.fullCircle;

        // Convert the angle into a number in the range [minValue, maxValue]
        const normalizedValue = (Math.sin(this.angle) + 1) / 2; // Normalize sin output to [0, 1]
        return normalizedValue;
    }
}