import { LitElement, html, css } from 'lit';
import { property, state, customElement } from 'lit/decorators.js';

@customElement('tabbed-box')
class TabbedBox extends LitElement {
    render() {
        return html`<slot></slot>`;
    }
}

@customElement('tabbed-box-item')
class TabbedBoxItem extends LitElement {
    static styles = css`
        :host {
            display: block;
            border: 1px solid var(--body-color);
        }

        .tab {
            cursor: pointer;
            padding: 1em;
            font-weight: bold;
            transition: all 0.3s ease;
            user-select: none;
            font-size: 1.3em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--hex-color);
        }

        .tab:hover {
            background-color: var(--hex-hover-color);
        }

        .tab.open {
            border-bottom: 1px solid var(--body-color);
        }

        .content {
            overflow: hidden;
            height: 0;
            transition: height 0.3s ease;
        }

        .caret {
            font-size: 0.8em;
            margin-left: 0.5em;
        }
    `;

    @property({ type: String })
    title: string = '';
    
    @property({ type: Boolean })
    defaultOpen: boolean = false;

    @state()
    private isOpen: boolean = false;

    private resizeObserver?: ResizeObserver;
    private mutationObserver?: MutationObserver;
    private lastHeight: string = '0px';

    firstUpdated() {
        this.isOpen = this.defaultOpen;
        
        if (this.isOpen) {
            this.updateHeight(true); // Set initial height if defaultOpen is true
        }

        const slot = this.shadowRoot!.querySelector('slot') as HTMLSlotElement;

        // Observe the slot itself for added/removed elements
        this.mutationObserver = new MutationObserver(() => this.handleSlotChange());
        this.mutationObserver.observe(slot, { childList: true });

        // Initialize ResizeObserver to monitor each slotted element for size changes
        this.handleSlotChange();
    }

    private handleSlotChange() {
        this.resizeObserver?.disconnect();

        // Re-observe each assigned element for resizing
        this.resizeObserver = new ResizeObserver(() => this.debouncedUpdateHeight());
        const assignedElements = (this.shadowRoot!.querySelector('slot') as HTMLSlotElement).assignedElements();
        assignedElements.forEach(element => this.resizeObserver!.observe(element));
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('isOpen')) {
            this.updateHeight(false);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.resizeObserver?.disconnect();
        this.mutationObserver?.disconnect();
    }

    private toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    private debouncedUpdateHeight() {
        requestAnimationFrame(() => this.updateHeight(false));
    }

    private updateHeight(immediate: boolean) {
        const content = this.shadowRoot!.querySelector('.content') as HTMLElement;
        
        if (this.isOpen) {
            content.style.height = 'auto';
            const fullHeight = `${content.scrollHeight}px`;

            // Avoid redundant height updates
            if (this.lastHeight !== fullHeight) {
                content.style.height = '0';
                requestAnimationFrame(() => {
                    content.style.transition = immediate ? 'none' : 'height 0.3s ease';
                    content.style.height = fullHeight;
                    this.lastHeight = fullHeight; // Update cached height
                });
            }
        } else {
            const collapseHeight = `${content.scrollHeight}px`;
            if (this.lastHeight !== '0px') {
                content.style.height = collapseHeight;
                requestAnimationFrame(() => {
                    content.style.transition = 'height 0.3s ease';
                    content.style.height = '0';
                    this.lastHeight = '0px';
                });
            }
        }
    }

    render() {
        return html`
            <div class="tab ${this.isOpen ? 'open' : ''}" @click=${this.toggleOpen}>
                ${this.title}
                <span class="caret">${this.isOpen ? '▲' : '▼'}</span>
            </div>
            <div class="content">
                <slot></slot>
            </div>
        `;
    }
}
