import { LitElement, html, css } from 'lit';
import { customElement, eventOptions, property } from 'lit/decorators.js';
import { ModalService } from '../modal-service/modal-service';

@customElement('info-box')
export class InfoBox extends LitElement {
    static styles = css`
        .info-box {
            position: relative;
            border-radius: 20px;
            background-color: var(--info-box-background-color);
            padding: 20px;
            text-align: var(--text-align, left);
            height: var(--height, 4.5vh);

            display: flex;
            flex-direction: column;
            justify-content: center;

            cursor: pointer;

            font-size: 0.9em;
            user-select: none;
            -webkit-user-select: none;
        }

        .info-icon {
            position: absolute;
            right: 8px;
            top: 8px;
            width: 1.1em;
        }

        info-box p {
            margin: 0;
            padding: 0;

            margin-top: 3px;
        }

        @keyframes fadeOutIn {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        .fade {
            animation: fadeOutIn 0.5s ease-in-out;
        }
    `;

    private currentMessageIndex = 0;
    private timer: any;
    private doRerender = true;

    private modalService = new ModalService();

    @property({ attribute: false }) messages: any = [html``];

    connectedCallback(): void {
        super.connectedCallback();

        this.setupTimeout();
    }

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    // Override shouldUpdate to prevent re-rendering
    shouldUpdate(changedProperties: Map<string | number | symbol, unknown>): boolean {
        // Add logic to determine when the element should update
        // Return false to prevent updating (and re-rendering)
        let doRender = this.doRerender;
        if (doRender) {
            this.doRerender = false;
        }
        return doRender;
    }

    render() {
        const message = typeof this.messages[this.currentMessageIndex] === 'function'
            ? this.messages[this.currentMessageIndex]()
            : this.messages[this.currentMessageIndex];

        const displayMessage = typeof message.displayMessage !== 'undefined'
            ? message.displayMessage
            : message;

        return html`
            <style>
                ${InfoBox.styles}
            </style>
            <div class="info-box" @click="${() => this.onClick()}">
                <img class="info-icon" src="./info.svg" @click="${(e: any) => {
                    e.stopPropagation();
                    this.showModal(this.currentMessageIndex);
                }}" />
                <p class="info-box-contents">
                    ${displayMessage}
                </p>
            </div>
        `;
    }

    private showModal(index: any): void {
        const message = typeof this.messages[index] === 'function'
            ? this.messages[index]()
            : this.messages[index];

        console.log(`[InfoBox] showing message`, message);
        // if it's a message with more information, grab info
        let modalId = "info-box-modal";
        let modalTemplate = html`<p>${message}</p>`;

        let modalTitle = "Information";
        let modalInfoButtons = [html`
            <styled-button @click="${() => this.modalService.hideModal(modalId)}">Close</styled-button>
        `]

        if (message.modalTemplate) {
            modalTitle = message.modalTitle;
            modalTemplate = message.modalTemplate;
            modalInfoButtons = [
                ...message.modalButtons,
                ...modalInfoButtons
            ]
        }

        const modalInfoButtonView = html`
            <br />
            <style>
                .info-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1em;
                }
            </style>
            <div class="info-buttons">
                ${modalInfoButtons}
            </div>
        `;

        this.modalService.showModal(
            modalId,
            modalTitle,
            [modalTemplate, modalInfoButtonView]
        );
    }

    private async onClick() {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
        this.setupTimeout();
        await this.triggerFade();

        this.reRender();
    }

    private setupTimeout(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(async () => {
            this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
            await this.triggerFade();
            this.reRender();
            this.setupTimeout();
        }, 8_000);
    }

    private async triggerFade() {
        const element = this.getElementsByClassName("info-box-contents")[0] as HTMLElement;
        element.classList.remove('fade'); // Remove class if it was previously added
        void element.offsetWidth; // Trigger reflow to restart animation
        element.classList.add('fade');

        // hard-coding half of animation duration
        return new Promise(resolve => setTimeout(resolve, 250));
    }

    private reRender() {
        this.doRerender = true;
        this.requestUpdate();
    }
}
