export class EventListenerHandle {

    constructor(
        private invokeUnsubscribe: Function[]
    ) {}

    unsubscribe(): void {
        this.invokeUnsubscribe.forEach(unsub => unsub());
    }

}