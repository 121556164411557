import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DailyPuzzleService } from '../daily-puzzle/daily-puzzle-service';
import { HexakaiGameBoardCreatorWebWorker } from '../hexakai-game/hexakai-game-board-creator-webworker';
import { HexakaiGameSession } from '../models/hexakai-game-session';
import { GameAchievementService } from '../achievements/game-achievement-service';
import { HexakaiGameSessionSerializer } from '../hexakai-game-session/hexakai-game-session-serializer';
import { ClientService } from '../client-service/client-service';
import { ModalService } from '../modal-service/modal-service';
import { DIFFICULTY_TEXTS } from '../app-texts/difficulty-texts';
import { YouTubeService } from '../youtube-service.ts/youtube-service';
import { CellValueType, Settings } from '../models/settings';
import { SettingsService } from '../settings-service/settings-service';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { HexakaiCellValueMapper } from '../hexakai-board/hexakai-cell-value-mapper';
import { UserEventsServiceComposite } from '../user-events/user-events-service-composite';

@customElement('daily-puzzles-view')
export class DailyPuzzlesView extends LitElement {

    private NUM_PUZZLES_IN_VIEW = 4;
    // store this, so if user waits until the next day, no bugs happen
    private startTopTimestamp = DailyPuzzleService.getStartOfDayTimestamp();

    private currentTopTimestamp = DailyPuzzleService.getStartOfDayTimestamp();

    private timestamps: {
        ts: number;
        no: number;
    }[] = [];
    private isEnd = false;
    private isStart = false;

    private clientService = new ClientService();
    private modalService = new ModalService();
    private youtubeService = new YouTubeService();

    // user settings
    private localStorageService = new LocalStorageService();
    private storageService = this.localStorageService;
    private settingsService = new SettingsService(
        this.storageService
    );
    private hexCellType!: CellValueType;

    private dailyPuzzleService = new DailyPuzzleService(
        new HexakaiGameBoardCreatorWebWorker(
            new UserEventsServiceComposite(
                this.clientService,
                this.localStorageService
            ),
            new LocalStorageService()
        ),
        this.clientService
    );
    
    // stored playlist info
    private playlistVideos = new Map<number, string>();
    private PLAYLIST_ID = `PL2my1Z1neT11Zat-d2pGacqVE1kbKUu8W`;

    static styles = css`
        .session-info-container {
            position: relative;
            border: 1px solid var(--body-color);
            border-radius: 10px;
            padding: 1em;
            cursor: pointer;
            margin-bottom: 1em;
        }

        .session-info-container:hover {
            background-color: var(--input-border-color);
        }

        .session-info {
            display: flex;
            text-align: left;
            padding-left: 5%;
            padding-top: 1em;
            padding-bottom: 1em;
        }

        .session-icon {
            width: 8em;
            aspect-ratio: 1;
            margin-right: 1em;
        }

        .info-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 1.8em;
            z-index: 1;
        }

        .session-buttons styled-button {
            margin-top: 0.25em;
            margin-left: 0.125em;
            margin-right: 0.125em;
        }
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.settingsService.onSettingsUpdated((settings: Settings) => {
            console.log(`[HexakaiBoard] settings update received`, settings);
            this.hexCellType = settings.cellValueType;
            this.requestUpdate();
        });
        this.hexCellType = CellValueType.standard;
        this.settingsService.getSettings().then(settings => {
            this.hexCellType = settings.cellValueType;
        });
        
        this.youtubeService.getVideosFromPlaylist(
            this.PLAYLIST_ID
        ).then(items => {
            this.playlistVideos = new Map<number, string>();
            for (const video of items) {
                const description = video.snippet.description;
                const date = this.scrapeDate(description);
                if (!date) {
                    continue;
                }

                const timestamp = DailyPuzzleService.getStartOfDayTimestamp(date);
                const videoId = video.snippet.resourceId.videoId;
                const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                this.playlistVideos.set(timestamp, videoUrl);
            }
            console.debug(`[DailyPuzzleView] playlist items mapped`, this.playlistVideos);

            this.requestUpdate();
        });
    }

    render() {
        const { timestamps, isEnd } = DailyPuzzleService.getPuzzleTimestamps(
            this.currentTopTimestamp,
            this.NUM_PUZZLES_IN_VIEW
        );
        this.timestamps = timestamps;
        this.isEnd = isEnd;
        this.isStart = timestamps[0].ts === this.startTopTimestamp;

        const renderTemplates: any[] = [];
        for (let i = 0; i < this.timestamps.length; i++) {
            const ts = this.timestamps[i];
            const params = this.dailyPuzzleService.getPuzzleParams(ts.ts);         

            const ach = GameAchievementService.getAchievementFromGame(
                params.gameSize,
                params.difficulty
            );
            const v = this.playlistVideos?.has(ts.ts)
                ? this.playlistVideos.get(ts.ts)
                : ''

            renderTemplates.push({
                ach,
                ts,
                p: params,
                v
            });
        }

        return html`
            <style>
                ${DailyPuzzlesView.styles}
            </style>
            ${renderTemplates.map((t: any) => html`
                <div class="session-info-container" @click="${() => this.openSession(t.ts.ts)}">
                    <h3>${t.ts.ts === this.startTopTimestamp ? "Today's Puzzle - " : ""}Puzzle #${t.ts.no}</h3>
                    <div class="session-info">
                        <img-smart class="session-icon" src="${t.ach.iconUrl}"></img-smart>
                        <div>
                            <p><b>Date: </b>${new Date(t.ts.ts).toLocaleDateString()}
                            <p><b>Game Size: </b>${t.p.gameSize}</p>
                            <p><b>Difficulty: </b>${t.p.difficulty}</p>
                        </div>
                    </div>
                    <div class="session-buttons">
                        <styled-button @click="${() => this.openSession(t.ts.ts)}">Play Puzzle!</styled-button>
                        ${t.v ? html`<styled-button @click="${(e: any) => {
                            e.stopPropagation();
                            window.open(t.v, "_blank");
                        }}">Watch Playthrough</styled-button>` : ``}
                    </div>
                    <img class="info-icon" src="./info.svg" @click="${(e: any) => {
                e.stopPropagation();
                this.showAboutPuzzle(t);
            }}" />
                </div>
            `)}
            <div id="puzzle-paginate">
                <styled-button style="${this.isStart ? 'display: none;' : ''}" @click="${() => this.goPrev()}">Previous</styled-button>
                <styled-button style="${this.isEnd ? 'display: none;' : ''}" @click="${() => this.goNext()}">Next</styled-button>
            </div>
        `;
    }

    private goPrev(): void {
        // if at the top, stop
        if (this.timestamps[0].ts >= this.startTopTimestamp) {
            return;
        }

        // update the positions and re-load data
        this.currentTopTimestamp =
            this.timestamps[0].ts + DailyPuzzleService.DAY_DURATION * this.NUM_PUZZLES_IN_VIEW;

        this.clientService.notifyInnerNavigationOccurred();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.requestUpdate();
    }

    private goNext(): void {
        const offset = DailyPuzzleService.DAY_DURATION * this.NUM_PUZZLES_IN_VIEW;

        // if at the top, stop
        if (this.timestamps[0].ts - offset < DailyPuzzleService.FIRST_TIMESTAMP) {
            return;
        }

        // update the positions and re-load data
        this.currentTopTimestamp =
            this.timestamps[0].ts -= offset;

        this.clientService.notifyInnerNavigationOccurred();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.requestUpdate();
    }

    private async openSession(ts: number): Promise<void> {
        const dayString = DailyPuzzleService.getDateString(+new Date(ts));

        const url = this.clientService.createLocalLink(
            "app",
            {
                d: dayString
            },
            true
        );

        window.location.href = url;
    }

    private showAboutPuzzle(t: any): void {
        const modalId = 'daily-puzzle-info';
        console.log(`[DailyPuzzleView] info icon clicked`, t);

        const { gameSize, difficulty } = t.p;
        const difficultyMessage = (DIFFICULTY_TEXTS as any)[difficulty];

        const possibleValues = HexakaiCellValueMapper.getPossibleValues(gameSize, this.hexCellType);
        const gameSizeMessage = html`
            On a board of size ${gameSize}, there are ${gameSize} diagonals in each direction, and the center row is ${gameSize} cells wide,
            for a total of ${gameSize * gameSize} cells on the board. Each cell has a range of ${gameSize} values it can be assigned.
            On this board size, the possible values are: ${possibleValues.join(", ")}.
        `;

        const displayDate = new Date(t.ts.ts).toLocaleDateString();
        const modalTemplate = html`
            <div style="text-align: left">
                <p><b>The puzzle for ${displayDate} is in ${difficulty} mode on a board of size ${gameSize}.</b>
                <p>${difficultyMessage}</p>
                <p>${gameSizeMessage}
            </div>
            <br />
            <div style="display: flex; gap: 1em; flex-wrap: wrap">
                <styled-button @click="${() => this.modalService.hideModal(modalId)}">Close</styled-button>
                <styled-button @click="${() => {
                this.openSession(t.ts.ts);
            }}">Play Puzzle!</styled-button>
            </div>
        `;

        this.modalService.showModal(
            modalId,
            `Daily Puzzle Info`,
            modalTemplate
        );
    }

    private scrapeDate(text: string): string {
        // format, Month name, month day, short or long year
        const monthRegex = /(jan(uary)?|feb(ruary)?|mar(ch)?|apr(il)?|may|june|july|aug(ust)?|sep(tember)?|oct(ober)?|nov(ember)?|dec(ember)?)/gmi;
        const dayRegex = /[0-3]?([0-9]+|(fir|seco|thi|four|fif|six|seven|eig|nin))(st|nd|rd|th)?/gmi;
        const yearRegex = /([0-9][0-9])?[0-9][0-9]/;

        const naturalLanguageRegex = new RegExp(
            `${monthRegex.source}\\s+${dayRegex.source}\\s*,?\\s+${yearRegex.source}`,
            "gmi"
        );
        const naturalMatch = text.match(naturalLanguageRegex);
        if (naturalMatch) {
            const monthMatch = naturalMatch[0].match(monthRegex)!;
            naturalMatch[0] = naturalMatch[0].replace(monthMatch[0], "");

            const dayMatch = naturalMatch[0].match(dayRegex)!;
            naturalMatch[0] = naturalMatch[0].replace(dayMatch[0], "");
            let day = dayMatch[0].replace(/[a-zA-Z]/g, "");

            const yearMatch = naturalMatch[0].match(yearRegex)!;
            let year = yearMatch[0];
            if (year.length === 2) {
                year = `20${year}`;
            }

            let month = -1;
            switch (monthMatch[0].toLowerCase().substring(0, 3)) {
                case "jan": month = 1; break;
                case "feb": month = 2; break;
                case "mar": month = 3; break;
                case "apr": month = 4; break;
                case "may": month = 5; break;
                case "jun": month = 6; break;
                case "jul": month = 7; break;
                case "aug": month = 8; break;
                case "sep": month = 9; break;
                case "oct": month = 10; break;
                case "nov": month = 11; break;
                case "dec": month = 12; break;
            }

            if (month > -1) {
                return `${year}-${month}-${day}`;
            }
        }

        // format: YYYY-MM-DD
        const sysRegex = /[0-9][0-9][0-9][0-9]-[0-2]?[0-9]-[0-3]?[0-9]/;
        const sysMatch = text.match(sysRegex);
        if (sysMatch) {
            const [y, m, d] = sysMatch[0].split("-");
            const year = parseInt(y);
            const month = parseInt(m);
            const day = parseInt(d);

            return `${year}-${month}-${day}`;
        }

        // format: MM/DD/YY(YY)?
        const stdRegex = /(0|1)?[0-9](\/|-)(0|1|2|3)?[0-9](\/|-)[0-9][0-9]([0-9][0-9])?/gmi;
        const stdMatch = text.match(stdRegex);
        if (stdMatch) {
            const delimiter = stdMatch[0].indexOf("-") > -1 ? "-" : "/";
            const [m, d, y] = stdMatch[0].split(delimiter);
            const year = parseInt(y);
            const month = parseInt(m);
            const day = parseInt(d);

            return `${year}-${month}-${day}`;
        }

        return '';
    }

    /*private async previewPuzzle(ts: number): Promise<void> {
        const modalId = "daily-puzzle-preview";
        const title = `Daily Puzzle Preview`
        const previewContainerId = "daily-preview-container";
        const contentTemplate = html`
            <div id="${previewContainerId}">
                <loading-display></loading-display>
            </div>
        `;

        this.modalService.showModal(
            modalId,
            title,
            contentTemplate
        );

        // asynchronously load the puzzle
        const session = await this.dailyPuzzleService.getPuzzleSession(ts);
        let boardString = "";
        const cells = session.boardState!.cells;
        for (let row=0; row<cells.length; row++) {
            for (let col=0; col<cells[row].length; col++) {
                boardString += cells[row][col] ? cells[row][col] : " ";
            }
        }

        const previewContainer = document.getElementById(previewContainerId)!;
        previewContainer.innerHTML = `<hexakai-board style="--hex-height: 40px;" mode="display" board="${boardString}"></hexakai-board>`;
    }*/
}