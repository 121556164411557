import { HexakaiBoardData } from "../../hexakai-board/hexakai-board-data";
import { HexakaiBoardStateVisitor } from "../../hexakai-board/hexakai-board-state-visitor";
import { ShufflerParams } from "../../models/shuffler-params";
import { getRandom } from "../get-random";
import { shuffleInPlace } from "../shuffle";
import { BaseShuffler } from "../shuffler";

export class HexEmptyCenterShuffler<T> extends BaseShuffler<T> {

    private gameSize = 10;
    private boardData: HexakaiBoardData;
    private visitor: HexakaiBoardStateVisitor<any>;
    private rowColMap: number[][] = [];
    private seed: any;

    constructor(
        params: ShufflerParams
    ) {
        super(params);
        if (params.seed) {
            this.seed = params.seed;
        }

        if (!params.data?.gameSize) {
            throw new Error("GameSize required in params!")
        }
        this.gameSize = params.data.gameSize;

        // put reverse map together
        this.boardData = new HexakaiBoardData(this.gameSize);
        let index = 0;
        const boardState = this.boardData.createBoardState((row, col) => {
            if (row === this.rowColMap.length) {
                this.rowColMap.push([]);
            }
            this.rowColMap[row].push(index);
            index ++;
        });
        this.visitor = new HexakaiBoardStateVisitor(this.boardData.createBoardState(0));

    }
    
    protected doShuffle(input: T[]): void {
        let isSearching = true;
        let index = 0;
        const debug: any = [];
        for (let inc = 0; isSearching; inc++) {
            let startIndex = index;

            // for each inc, record the new indices, shuffle them, and swap assignments
            // this introduces more randomness
            const indicesToSwap: number[] = [];

            for (let rowInc = 0; rowInc <= inc; rowInc ++) {
                const rowA = this.gameSize - 1 - inc;
                const rowB = this.gameSize - 1 + inc;

                const numCols = this.boardData.getNumCols(rowA);
                const halfRow = numCols / 2
                const rowCenter = halfRow % 1 === 0
                    ? halfRow
                    : Math.floor(halfRow)
                        + ((Math.floor(halfRow) < numCols - 1 && getRandom(this.seed) < 0.5) ? 1 : 0);

                
                const hideColA = rowCenter - rowInc;
                const hideColB = rowCenter + rowInc;

                let newIndex: number;
                // swap hideColA in rowA
                if (hideColA > 0) {
                    newIndex = this.rowColMap[rowA][hideColA];
                    //this.swapIndicesIfUntouched(input, index++, newIndex);
                    indicesToSwap.push(newIndex);
                    debug.push(`${rowA},${hideColA}:${inc}`);
                }
                // swap hideColB in rowA
                if (hideColB !== hideColA && hideColB < this.rowColMap[rowA].length -1) {
                    newIndex = this.rowColMap[rowA][hideColB];
                    //this.swapIndicesIfUntouched(input, index++, newIndex);
                    indicesToSwap.push(newIndex);
                    debug.push(`${rowA},${hideColB}:${inc}`);
                }
                // swap with rowB
                if (rowB !== rowA) {
                    if (hideColA > 0) {
                        newIndex = this.rowColMap[rowB][hideColA];
                        //this.swapIndicesIfUntouched(input, index++, newIndex);
                        indicesToSwap.push(newIndex);
                        debug.push(`${rowB},${hideColA}:${inc}`);
                    }
                    // swap hideColB in rowA
                    if (hideColB !== hideColA && hideColB < this.rowColMap[rowB].length -1) {
                        newIndex = this.rowColMap[rowB][hideColB];
                        //this.swapIndicesIfUntouched(input, index++, newIndex);
                        indicesToSwap.push(newIndex);
                        debug.push(`${rowB},${hideColB}:${inc}`);
                    }
                }
            }

            shuffleInPlace(indicesToSwap, this.seed);
            for (const newIndex of indicesToSwap) {
                this.swapIndicesIfUntouched(input, index++, newIndex);
            }

            // if no assignments made, exit
            if (startIndex === index) {
                isSearching = false;
                input.reverse();
            }
        }
    }

    private swapIndicesIfUntouched(input: any[], indA: number, indB: number): void {
        const tmp = input[indA];
        input[indA] = input[indB];
        input[indB] = tmp;
    }
}