import { RngParams, RngType } from '../models/rng-params';
import { RngFactory } from './rng-factory';

const factory = new RngFactory();

/**
 * Get a random number based on a seed.
 * If no seed provided, use Math.random to generate the seed
 */
export function getRandom(param?: number | string | RngParams): number {
    return getRandomInvoker(param)();
}

/**
 * Get an invoker,
 * Unlike getrandom, use a custom invoker to preserve deterministic behavior
 * (i.e., calling the random more than once affects future generations)
 */
export function getRandomInvoker(
    param?: number | string | RngParams,
    getNew?: boolean
): () => number {
    if (typeof param === 'undefined') {
        param = <RngParams>{
            rngtype: RngType.default,
            seed: 0
        };
    } else if (typeof param === 'number' || typeof param === 'string') {
        param = <RngParams>{
            rngtype: RngType.mkAlea,
            seed: param
        };
    }

    const instance = getNew
        ? factory.getNew(param as RngParams)
        : factory.get(param as RngParams);
    return instance.getRandom.bind(instance);
}

